import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/core/auth/services/token.service';
import { BaseService } from 'src/app/core/services/base.service';
import { ResultSearchModel } from '../../../shared/components/base-resource-form/model/result-search.model';
import { BookingModel } from '../models/booking.model';

@Injectable({
  providedIn: 'root'
})
export class BookingService extends BaseService<BookingModel, string> {

  constructor(protected injector: Injector, private tokenService: TokenService) {
    super(injector, '/api/booking');
  }

  public pageableSearch(queryString: string): Observable<ResultSearchModel<BookingModel>> {
    return this.search(queryString);
  }

  public update(agent: BookingModel) {
    return this.post(agent, `/edit/${agent.id}`);
  }

  // public resendVoucher(bookingId: number) {
  //   return this.postAny({}, `/send-booking-voucher-email/${bookingId}`);
  // }

  public getStatus(): Observable<any> {
    return this.get('/status');
  }

  public bookingTypes(): Observable<any> {
    return this.get('/booking-types');
  }

  public getBooking(id: string): Observable<any> {
    return this.get(`/${id}`);
  }

  public getVoucher(id: any, type: any): Observable<any> {
    return this.get(`/voucher/${id}?emailVoucherType=${type}`);
  }

  public cancelBooking(id: number, reason: any): Observable<any> {
    return this.put(`${id}`, reason, '/hotel/cancel');
  }

  public voucherResend(obj: any, bookingId: any) {
    return this.post(obj, `/send-booking-voucher-email/${bookingId}`);
  }

  public recalculateMarkup(bookingId: any, params: any): Observable<any> {
    return this.post(params, `/recalculate-markup/${bookingId}`);
  }

  public applyChangeMarkup(bookingId: any, params: any): Observable<any> {
    return this.post(params, `/apply-change-markup/${bookingId}`);
  }

  public getTicketInformations(bookingId: any): Observable<any> {
    return this.get(`/locators/${bookingId}`)
  }

  public postTicketInformations(flightLocators: any): Observable<any> {
    return this.post(flightLocators, `/locators`)
  }


}
