import { CurrencyPipe, DatePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ActivePipe } from './active.pipe';
import { BoldtextPipe } from './boldtext.pipe';
import { CpfPipe } from './cpf.pipe';
import { CurrencyFormatPipe } from './currency-format.pipe';
import { DataPipe } from './date.pipe';
import { LanguageFormatPipe } from './language-format.pipe';
import { ViewImagePipe } from './view-image.pipe';
import { SanitizerPipe } from './sanitize.pipe';

@NgModule({
    imports: [
    ],
    providers: [
      DatePipe, CurrencyPipe, CurrencyFormatPipe, BoldtextPipe
    ],
    declarations: [
      CpfPipe,
      ActivePipe,
      ViewImagePipe,
      CurrencyFormatPipe,
      DataPipe,
      BoldtextPipe,
      LanguageFormatPipe,
      SanitizerPipe
    ],
    exports: [
      CpfPipe,
      ActivePipe,
      ViewImagePipe,
      CurrencyFormatPipe,
      DataPipe,
      BoldtextPipe,
      LanguageFormatPipe,
      SanitizerPipe
    ],
  })
  export class PipeModule {
    static forRoot(): ModuleWithProviders<PipeModule> {
    return {
        ngModule: PipeModule,
        providers: [],
    };
}
  }
