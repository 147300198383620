export default class Messages {

  // Messages ERROR
  public static readonly REQUIRED_FIELDS = 'messages.required.fields';
  public static readonly ERROR_INTERNAL = 'messages.error.internal';
  public static readonly FORBIDEN_REQUEST = 'messages.error.forbiden';
  public static readonly INVALID_FIELD = 'messages.invalid.field';
  public static readonly SUCCESS_SAVE = 'messages.success.save';
  public static readonly SUCCESS_UPDATE = 'messages.success.update';
  public static readonly SUCCESS_DELETE = 'messages.success.delete';
  public static readonly ACTION_NOT_POSSIBLE = 'messages.action.not-possible';
  public static readonly HOME_FIRST_ACCESS = 'messages.home.first.access';
  public static readonly CURRENCY_DUPLICATE = 'messages.invalid.exchange';
  public static readonly IMAGE_RESOLUTION= 'messages.invalid.imageresolution';
  public static readonly INVALID_DATE_RANGE= 'messages.invalid.daterange';
  public static readonly INVALID_CODE= 'messages.invalid.code';
  public static readonly INVALID_EMAIL= 'messages.invalid.email';

  public static readonly LOGIN_ERROR_USER_PASSWORD = 'login.error.user.password';
  public static readonly LOGIN_ERROR_RESET_PASSWORD = 'login.error.reset.password';
  public static readonly LOGIN_SUCCESS_RESET_PASSWORD = 'login.success.reset.password';
  public static readonly LOGIN_SUCCESS_UPDATE_PASSWORD = 'login.success.update.password';
  public static readonly LOGIN_WARNING_RESET_PASSWORD = 'login.warning.reset.password';

  public static readonly BOOKING_CANCEL_SUCCESS = 'booking.detail.messages.cancel-booking';
  public static readonly INVALID_VALUES_MATCH = 'sales.match-values';

  public static readonly MANUAL_SALE_ERROR = 'sales.message.manual_sale_error';
  public static readonly INTEREST_RATE_INVALID = 'sales.message.interest-rate-invalid';
  public static readonly INTEREST_INVALID = 'sales.message.interest-invalid';
  public static readonly INTEREST_LESS_THAN_TOTAL = 'sales.message.interest-less-than-total';
  public static readonly SALE_CANNOT_RESCHECULE = 'sales.message.sale-cannot-reschedule';

  public static readonly CustomerInvoiceNoItems = 'invoice.message.noItemsInvoice';
  public static readonly  DIFF_VALUE_TOTAL_PAYMENT= 'bonus-demonstrative-payment.message.diff-value';
  public static readonly  EMPTY_LIST_BONUS_DEMONSTRATIVE= 'bonus-demonstrative-payment.message.empty-list';

  public static readonly EMAIL_EXISTS = 'messages.duplicated-fields.email-exists';
  public static readonly DOCUMENT_EXISTS = 'messages.duplicated-fields.document-exists';
  public static readonly AGENCY_NAME_EXISTS = 'messages.duplicated-fields.agency-name-exists';
  public static readonly DOCUMENT_NOT_ALLOWED = 'messages.black-list.document-not-allowed';
  public static readonly AGENCYNAME_NOT_ALLOWED = 'messages.black-list.agency-not-allowed';

}
