import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LanguageService } from 'src/app/components/admin/points-interest/services/language.service';
import Messages from 'src/app/components/shared/enums/messages.enum';
import { NotificationService } from '../services/notification.service';
import { TokenService } from './../auth/services/token.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private loaderService: NgxSpinnerService,
    private tokenService: TokenService,
    private languageService: LanguageService,
    private notificationService: NotificationService,
    private translate: TranslateService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenService.getToken('userToken');
    var language = this.languageService.CurrentilyLanguageId ? this.languageService.CurrentilyLanguageId.toString() : '1';
    if (!!token) {
      if (request.url !== 'https://jsonip.com') {
        const clonedReq = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            Platform: 'Backoffice',
            Language: language,
            InstitutionId: this.tokenService.getToken(this.tokenService.TOKEN_INTITUTION_ID) + ''
          }
        });
        return this.verifyRequest(clonedReq, next);
      } else {
        return next.handle(request.clone());
      }
    } else {
      return this.verifyRequest(request.clone(), next);
    }
  }

  private verifyRequest(requisicao: HttpRequest<any>, handlerHttp: HttpHandler) {
    return handlerHttp.handle(requisicao).pipe(tap(res => {
      if (res instanceof HttpResponse) {
        // FINALIZAR LOADING;
        this.loaderService.hide();
      }
    }),
      catchError(err => {
        // console.log('Erro na requisição');
        // FINALIZAR LOADING;
        this.loaderService.hide();
        if (err.status == 403) {
          this.notificationService.messageError(this.translate.instant(Messages.FORBIDEN_REQUEST));
          return EMPTY;
        } else {
          throw err;
        }
      })
    );
  }
}
