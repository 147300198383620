<div class="border rounded p-2">
    <div class="picture d-flex align-items-center justify-content-center">
        <img *ngIf="croppedImage;else imageUploaded" [src]="croppedImage" />
        <ng-template #imageUploaded>
            <img *ngIf="imageUrl;else uploadTextWithouPartner" [src]="imageUrl" />
            <ng-template #uploadTextWithouPartner>
                <p>{{'offer-template.edit.text.select-image' | translate}}</p>
            </ng-template>
        </ng-template>
    </div>
    <div class="d-flex justify-content-center">
        <input type="file" id="file-upload" (change)="fileChangeEvent($event)" class="d-none" #imageFile>
        <button (click)="clickImageFile()" class="btn btn-primary">
            {{'general.button.title.select-image' | translate }}
        </button>
    </div>
</div>