import { Component, EventEmitter, Injector, Input, OnChanges, Output } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { HotelModel } from 'src/app/components/admin/hotel/models/hotel.model';
import { ImageModel } from 'src/app/components/admin/hotel/models/image.model';
import { BaseResourceFormComponent } from '../base-resource-form/base-resource-form.component';


@Component({
    selector: 'app-hotel-card',
    templateUrl: './hotel-card.component.html',
    styleUrls: ['./hotel-card.component.css'],
    providers: [NgbCarouselConfig]
})
export class HotelCardComponent extends BaseResourceFormComponent implements OnChanges {

    @Input() hotelData: HotelModel;
    @Input() images: ImageModel[];
    @Output() activateDeactivateImage = new EventEmitter();

    hotel: HotelModel;
    order = true;
    constructor(injector: Injector, config: NgbCarouselConfig) {
        super(injector);
        config.keyboard = false;
        config.pauseOnHover = true;
    }

    protected createFrom(): void {
    }

    numberArray(value: number): number[] {
        return new Array(value);
    }

    ngOnChanges(): void {
        if (this.hotelData) {
            this.hotel = this.hotelData;
        }
    }

    public changeToImage(n: string) {
        const ol = document.createElement('ol');
        const li = document.createElement('li');
        const div = document.getElementById(`carouselExampleControls-${this.hotel.id}`);
        ol.setAttribute('class', 'carousel-indicators');
        ol.setAttribute('style', 'display: none');
        li.setAttribute('data-slide-to', n);
        li.setAttribute('data-target', `#carouselExampleControls-${this.hotel.id}`);
        ol.append(li);
        div.append(ol);
        li.click();
        ol.remove();
    }

    activateDeactivate(imageModel: ImageModel) {
        this.activateDeactivateImage.emit(this.images.indexOf(imageModel) + '');
    }

    public defineTooltip(isActive: boolean): string {
        const prefix = isActive ? 'de' : '';
        return this.translate.instant(`image-viewer.tooltip.${prefix}activate`);
    }
}
