<body class="hold-transition login-page" style="background-color: #343a40;">

    <div class="login-box">
        <div class="card">
            <div class="card-body login-card-body">

                <div class="card-head">
                    <div class="title" *ngIf="hasHashValid">
                        {{ 'authentication.created-store' | translate }}
                    </div>

                    <div class="title-login" *ngIf="!hasHashValid">
                        {{'login.label.title' | translate}}
                    </div>

                    <div class="contacts" *ngIf="hasHashValid">
                        {{ 'authentication.to-your-email' | translate }} <b>{{anonymousEmail}}</b> {{ 'authentication.to-your-phone' | translate }} <b>{{anonymousPhone}}</b>.
                    </div>
                </div>

                <form novalidate [formGroup]="form" class="form" *ngIf="!isByPassword">
                    <div *ngIf="!hasHashValid">
                        <div class="input-form">
                            <label for="email" class="input-label">{{'authentication.input-email-label' | translate}}</label>
                            <input style="height:50px;" (input)="check($event)" type="email" formControlName="email" class="form-control" placeholder="{{'login.label.email' | translate}}" name="email" [ngClass]="{'ng-touched': formSubmitted, 'ng-invalid': errorEmail || (formSubmitted && !form.controls.email.valid)}">
                        </div>

                        <div class="d-flex flex-column">
                            <small class="error text-danger ml-2 mt-1" *ngIf="form.controls.email.errors?.required && (form.controls.email?.dirty || form.controls.email?.touched)">
                                <span class="fas fa-times-circle"></span>
                                {{ 'authentication.required-field' | translate }}
                            </small>
                            <small class="error text-danger ml-2 mt-1" *ngIf="errorEmail">
                                <span class="fas fa-times-circle"></span>
                                {{errorEmail}}
                            </small>
                        </div>
                    </div>

                    <div *ngIf="isSentEmail">
                        <div class="insert-code">
                            {{ 'authentication.insert-your-code' | translate }}
                        </div>
                        <div class="input-form">
                            <label for="authenticationCode" class="input-label">{{'authentication.input-code-label' | translate}}</label>
                            <input style="height:50px" (input)="check($event)" type="text" formControlName="authenticationCode" class="form-control" placeholder="{{codePlaceholder | translate}}" name="authenticationCode" [ngClass]="{'ng-touched': formSubmitted, 'ng-invalid': errorCode || (formSubmitted && !form.controls.authenticationCode.valid)}">
                        </div>

                        <div class="d-flex flex-column">
                            <small class="error text-danger ml-2 mt-1" *ngIf="form.controls.authenticationCode.errors?.required && (form.controls.authenticationCode?.dirty || form.controls.authenticationCode?.touched)">
                                <span class="fas fa-times-circle"></span>
                                {{ 'authentication.required-field' | translate }}
                            </small>
                            <small class="error text-danger ml-2 mt-1" *ngIf="errorCode">
                                <span class="fas fa-times-circle"></span>
                                {{errorCode}}
                            </small>
                        </div>

                        <div class="remember-user mr-3 mt-2">
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" id="remember" formControlName="remember">
                                <label for="remember" class="custom-control-label">
                                    {{ 'authentication.remember-user' | translate }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="row d-flex justify-content-center">
                        <div class="col-12" *ngIf="isSentEmail">
                            <button type="submit" class="btn btn-primary btn-block option-button" (click)="validate()">
                              {{'login.button.getin' | translate}}
                            </button>
                        </div>
                        <div class="col-12" *ngIf="!isSentEmail">
                            <button type="submit" class="btn btn-primary btn-block option-button" (click)="sendCode()">
                                {{ 'authentication.send' | translate }}
                            </button>
                        </div>

                        <div class="col-12 reset-pass" *ngIf="!hasHashValid" (click)="onByPassword()">
                          {{ 'authentication.access-with-password' | translate }}
                        </div>

                        <div class="col-12" *ngIf="isSentEmail">
                            <div class="col-12 resend-code">
                                {{ 'authentication.not-received' | translate }} <span (click)="sendCode(true)">{{ 'authentication.resend' | translate }}</span>
                            </div>
                        </div>

                    </div>
                </form>

                <form novalidate [formGroup]="formByPassword" class="form" *ngIf="isByPassword">
                    <div class="input-form">
                        <label for="login" class="input-label">{{'authentication.input-email-label' | translate}}</label>
                        <input style="height:50px;" (input)="check($event)" type="email" formControlName="login" class="form-control" placeholder="{{'login.label.email' | translate}}" name="login" [ngClass]="{'ng-touched': formSubmitted, 'ng-invalid': errorEmail || (formSubmitted && !formByPassword.controls.login.valid)}">
                    </div>

                    <div class="d-flex flex-column">
                        <small class="error text-danger ml-2 mt-1" *ngIf="formByPassword.controls.login.errors?.required && (formByPassword.controls.login?.dirty || formByPassword.controls.login?.touched)">
                            <span class="fas fa-times-circle"></span>
                            {{ 'authentication.required-field' | translate }}
                        </small>
                    </div>

                    <div class="input-form mt-3">
                        <label for="password" class="input-label">{{ 'authentication.password' | translate }}</label>
                        <input style="height:50px" (input)="check($event)" [type]="hidePassword ? 'text' : 'password'" formControlName="password" class="form-control" placeholder="{{'login.label.password' | translate}}" name="password" [ngClass]="{'ng-touched': formSubmitted, 'ng-invalid': errorCode || (formSubmitted && !formByPassword.controls.password.valid)}">
                        <span class="p-viewer" (click)="onHidePassword()">
                          <i class="fa fa-eye" aria-hidden="true" *ngIf="hidePassword"></i>
                          <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="!hidePassword"></i>
                        </span>
                    </div>

                    <div class="d-flex flex-column">
                        <small class="error text-danger ml-2 mt-1" *ngIf="formByPassword.controls.password.errors?.required && (formByPassword.controls.password?.dirty || formByPassword.controls.password?.touched)">
                            <span class="fas fa-times-circle"></span>
                            {{ 'authentication.required-field' | translate }}
                        </small>
                        <small class="error text-danger ml-2 mt-1" *ngIf="errorLogin">
                          <span class="fas fa-times-circle"></span>
                          {{errorLogin}}
                      </small>
                    </div>

                    <div class="remember-user mr-3 mt-2">
                        <div class="custom-control custom-checkbox">
                            <input class="custom-control-input" type="checkbox" id="remember" formControlName="remember">
                            <label for="remember" class="custom-control-label">
                                {{ 'authentication.remember-user' | translate }}
                            </label>
                        </div>
                    </div>

                    <div class="row d-flex justify-content-center">
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary btn-block option-button" (click)="loginByPassword()">
                              {{'login.button.getin' | translate}}
                            </button>
                        </div>

                        <div class="col-12 reset-pass" (click)="onByPassword(isByPassword)">
                          {{ 'authentication.access-with-code' | translate }}
                        </div>

                        <div class="col-12">
                            <div class="col-12 resend-code">
                              {{ 'authentication.forgot-password' | translate }} <span (click)="resetPassword()">{{ 'authentication.recover' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row d-flex justify-content-center">
                    <div class="col-12 area-adm">
                        {{ 'authentication.restric-area' | translate }}
                    </div>
                </div>

            </div>
        </div>
    </div>
</body>
