import { Pipe, PipeTransform } from '@angular/core';
import Languages from '../enums/languages.enum';

@Pipe({name: 'language'})
export class LanguageFormatPipe implements PipeTransform {

  constructor() { }

  transform(value: string,sigla: string): string {
    let valueFormated = '';

    switch (sigla) {
      // BRL
      case 'en-us':
        valueFormated =  Languages.ENGLISH.name;
        break;
      case 'pt':
        valueFormated =  Languages.PORTUGUESE.name;
        break;
      case 'es':
        valueFormated =  Languages.SPANISH.name;
        break;
      case 'it':
        valueFormated =  Languages.ITALIAN.name;
        break;
      case 'de':
        valueFormated =  Languages.GERMAN.name;
        break;
      case 'fr':
        valueFormated =  Languages.FRENCH.name;
        break;
        case 'ptBr':
        valueFormated =  Languages.BR_PORTUGUESE.name;
        break;

      default:
        valueFormated = value;
        break;
    }
    return valueFormated;
  }
}
