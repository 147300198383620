import { Component, OnInit, AfterViewInit, ApplicationRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CurrencieService } from './core/services/currencie.service';
import { interval } from 'rxjs';
import { TokenService } from './core/auth/services/token.service';
import { AuthService } from './core/auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ModalTermsConditionsComponent } from './components/shared/components/modal-terms-conditions/modal-terms-conditions.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LanguageService } from './components/admin/points-interest/services/language.service';
import { NotificationService } from './core/services/notification.service';
import { AgentService } from './components/admin/partners/services/partner.service';
import { ModalEndTermComponent } from './components/shared/components/modal-end-term/modal-end-term.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {

  langs = ['pt', 'en-us'];
  clientIp: string;
  modalRef: BsModalRef;
  languages: any;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private appRef: ApplicationRef,
    private authService: AuthService,
    private tokenService: TokenService,
    private http: HttpClient,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private agentService: AgentService
  ) {
  }

  ngOnInit(): void {
    // const isTokenExpired = this.authService.check();
    // if (isTokenExpired) {
    //   this.logout();
    // }

    this.http.get<any>(`${environment.apiIpAddressUrl}`).subscribe((data: any) => {
      this.clientIp = data.ip;
      localStorage.setItem('client-ip', this.clientIp);
    }, err => {
      // console.log(err);
    });

    // this.checkEndTerm();
  }

  checkEndTerm(): void {
    this.agentService.getEndTerm().subscribe((result: any) => {
      console.log(result);
      if (result.data === false) {
        this.openEndTermModal();
      }
    });
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  checkTermsAndConditions(authToken: any): void {
    const tokenDecoded: any = this.tokenService.decodeToken(authToken);
    if (tokenDecoded.role === 'AGENT' && tokenDecoded.AcceptedTermsAndContract === 'False') {
      this.openModalTermsConditions();
    }
  }

  openModalTermsConditions(): void {
    this.modalRef = this.modalService.show(ModalTermsConditionsComponent,
      {
        backdrop: true,
        animated: true,
        ignoreBackdropClick: true,
        keyboard: false,
        initialState: {
          callback: (result: boolean) => {
            if (result) {
              // console.log(result);
            }
          }
        }
      });
  }

  openEndTermModal(): void {
    this.modalRef = this.modalService.show(ModalEndTermComponent,
      {
        backdrop: true,
        animated: true,
        ignoreBackdropClick: true,
        keyboard: false,
        initialState: {
          callback: (result: boolean) => {
            if (result) {
              // console.log(result);
            }
          }
        }
      });
  }
}
