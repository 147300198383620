<div id="sign-in-dialog" class="zoom-anim-dialog" style="padding: 1.5rem;">
  <div class="sign-in-wrapper mt-sm-4 mt-5">
    <div class="d-flex flex-row justify-content-center mt-3">
      <img src="assets/img/terms.svg" width="130px" alt="">
    </div>

    <div class="d-flex flex-row justify-content-center mt-3">
      <h4 class="app-f18 text-center">
        {{'modal-terms.title-text' | translate}}
      </h4>
    </div>

    <div class="d-flex flex-row mt-3">
      <h6 class="form-check-subtitle">{{'modal-terms.subtitle-text' | translate}}</h6>
    </div>

    <div class="d-flex flex-row mt-3">
      <div class="form-check">
        <input class="form-check-input form-check-input-lg" type="checkbox" [checked]="checkedTerms" id="termsCheckbox"
          (click)="setAcceptedTerms()">
        <label class="form-check-label" for="termsCheckbox">
          <span class="form-check-label-span">{{'modal-terms.confirm-check' | translate}} </span>
          <a class="form-check-label-link" *ngFor="let item of terms; last as isLast" href="{{item.url}}"
            target="_blank">
            {{ getTermNameToTranslate(item.name) | translate }}<span *ngIf="!isLast">, </span>
          </a>
        </label>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12 d-flex justify-content-center align-items-center">
        <button type="button" class="btn btn-danger w-50 font-weight-bold app-uppercase mr-1" (click)="onNotNow()">
          {{'modal-terms.not-now' | translate}}
        </button>
        <button type="button" class="btn btn-success w-50 font-weight-bold app-uppercase ml-1" (click)="onAcceptTerms()"
          [disabled]="!checkedTerms">
          {{'modal-terms.agree' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>