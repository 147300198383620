import { HotelCardViewComponent } from './components/hotel-card-view/hotel-card-view.component';
import { TreeviewDirective } from './directives/treeview.directive';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { ConsultaColunasComponent } from './components/consulta/consulta-colunas.component';
import { HotelCardComponent } from './components/hotel-card.component/hotel-card.component';
import { ImageCropperModalModule } from './components/image-cropper/image-cropper-modal.module';
import { FooterComponent } from './components/template/footer/footer.component';
import { HeaderLoginComponent } from './components/template/header-login/header-login.component';
import { HeaderComponent } from './components/template/header/header.component';
import { MenuComponent } from './components/template/menu/menu.component';
import { PipeModule } from './pipes/pipe.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { VoucherResendComponent } from './components/voucher-resend/voucher-resend.component';
import { NgxCurrencyModule } from "ngx-currency";
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ModalTermsConditionsComponent } from './components/modal-terms-conditions/modal-terms-conditions.component';
import { ModalTermsConditionsComissionComponent } from './components/modal-terms-conditions/modal-terms-conditions-comission/modal-terms-conditions-comission/modal-terms-conditions-comission.component';
import { OfferTemplateThumbUploadComponent } from './components/offer-template-thumb-upload/offer-template-thumb-upload.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { OnertravelVersionInfoModule } from '@onertravel/version-info';
import { SwitchComponent } from './components/switch/switch.component';
import { SearchPartnerComponent } from './components/search-partner/search-partner.component';
import { MaterialModule } from './material/material.modules';
import { ModalEndTermComponent } from './components/modal-end-term/modal-end-term.component';
import { HomeInformationCarouselViewComponent } from './components/home-information-carousel-view/home-information-carousel-view.component';
import { ConfirmModalDelete } from './components/confirm-modal-delete/confirm-modal-delete.component';
import { ModalImageDetailComponent } from './components/modal-image-detail/modal-image-detail.component';
import { CustomIconComponent } from './components/custom-icon/custom-icon.component';
import { NoSpecialCharsDirective } from './directives/no-special-caractres.directive';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    HeaderLoginComponent,
    ConsultaColunasComponent,
    HotelCardComponent,
    TreeviewDirective,
    NoSpecialCharsDirective,
    HotelCardViewComponent,
    VoucherResendComponent,
    ConfirmModalComponent,
    ModalEndTermComponent,
    ModalTermsConditionsComponent,
    ModalTermsConditionsComissionComponent,
    OfferTemplateThumbUploadComponent,
    SwitchComponent,
    SearchPartnerComponent,
    HomeInformationCarouselViewComponent,
    ConfirmModalDelete,
    ModalImageDetailComponent,
    CustomIconComponent
  ],
  imports: [
    OnertravelVersionInfoModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-full-width',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'increasing'
    }),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    NgxPaginationModule,
    PipeModule,
    TranslateModule,
    ImageCropperModalModule,
    TooltipModule.forRoot(),
    NgbModule,
    CarouselModule.forRoot(),
    NgSelectModule,
    NgxCurrencyModule,
    TypeaheadModule.forRoot(),
    MaterialModule
  ],
  exports: [
    ToastrModule,
    BsDatepickerModule,
    TimepickerModule,
    NgxMaskModule,
    NgxPaginationModule,
    PipeModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModalModule,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    HeaderLoginComponent,
    TranslateModule,
    ConsultaColunasComponent,
    TooltipModule,
    NgbModule,
    HotelCardComponent,
    CarouselModule,
    TreeviewDirective,
    NoSpecialCharsDirective,
    NgSelectModule,
    HotelCardViewComponent,
    VoucherResendComponent,
    NgxCurrencyModule,
    ConfirmModalComponent,
    ModalTermsConditionsComponent,
    OfferTemplateThumbUploadComponent,
    TypeaheadModule,
    SwitchComponent,
    SearchPartnerComponent,
    MaterialModule,
    HomeInformationCarouselViewComponent,
    ConfirmModalDelete,
    ModalImageDetailComponent,
    CustomIconComponent
  ]
})
export class SharedModule { }
