import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { defineLocale, listLocales } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminModule } from './components/admin/admin.module';
import { AuthModule } from './components/login/auth.module';
import { AuthGuard } from './core/guards/auth.guard';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { WebpackTranslateLoader } from './webpacktranslate';
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import localeFr from '@angular/common/locales/fr';
import {registerLocaleData} from '@angular/common';
import { environment } from '../environments/environment';
import { OnertravelVersionInfoModule } from '@onertravel/version-info';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { PaginatorIntlService } from 'src/assets/i18n/paginator/paginatorI18n';
registerLocaleData(localePt)
defineLocale('pt-br', ptBrLocale);
registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    AdminModule,
    NgxSpinnerModule,
    NgbModule,
    NgxPaginationModule ,
    ModalModule.forRoot(),
    OnertravelVersionInfoModule,
    TranslateModule.forRoot({
      loader:
      {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    })
  ],
  providers: [
    AuthGuard,
    {
      provide: MatPaginatorIntl,
      useFactory: (translate: TranslateService) => {
        const service = new PaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "pt-BR" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  locales = listLocales();

  constructor(private localeService: BsLocaleService) {
    this.localeService.use('pt-br');
  }
}
