<nav class="main-header navbar navbar-expand navbar-dark navbar-success" style="background-color: #343a40;">
  <!-- Right navbar links -->
  <ul class="navbar-nav ml-auto">
    <div style="display: flex; align-items: center;">
      <li class="height-full dropdown dropdown-sm list-style-none">
        <a class="dropdown-item dropdown-footer hover-white" style="display: flex;" id="dropdownMenuButton"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class=" mr-md-2 mr-lg-2 mr-xl-2">
            <img [src]="currentFlag?.icon" style="width: 17px;" alt="">
          </div>
          <span class="color text-white">{{'menu.label.header.language' | translate}} </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
          <div *ngFor="let language of languages">
            <a class="dropdown-item" (click)="useLanguage(language)">
              <img [src]="language.icon" style="width: 17px;" alt="">
              &nbsp;{{language.displayName}}
              <i class="fa fa-check" *ngIf="idioma === language.codeIso" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </li>
    </div>
  </ul>
</nav>
