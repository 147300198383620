import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: '<app-switch>',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {

  @Input() switchForm: UntypedFormGroup;
  @Input() status: boolean;
  @Output() statusChange = new EventEmitter<boolean>();

  constructor() {
    this.switchForm = new UntypedFormGroup({
      couponStatus: new UntypedFormControl(this.status)
    });
  }

  ngOnInit(): void { }

  changeStatus() {
    this.statusChange.emit(this.status);
  }

}
