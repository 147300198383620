import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ResultObjectModel } from 'src/app/components/shared/components/base-resource-form/model/result-object.model';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
@Injectable({
    providedIn: 'root'
})
export class AppLanguageResolver implements Resolve<ResultObjectModel<any>> {

    constructor(private configurationService: ConfigurationService,
    ) { }

    resolve(
        route: ActivatedRouteSnapshot
    ): Observable<any> | Promise<any> | any {
        return this.configurationService.getLanguagesActive();
    }
}
