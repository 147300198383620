import { Observable } from 'rxjs';
import { ComissionModel } from './../models/comission.model';
import { BaseService } from './../../../core/services/base.service';
import { Injectable, Injector } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ComissionService extends BaseService<ComissionModel, string> {

  constructor(protected injector: Injector) {
    super(injector, '/api/comission');
  }

  public getComission(): Observable<ComissionModel> {
    return this.get();
  }

  public getComissionByInstitutionId(institutionId: number): Observable<ComissionModel> {
    return this.get(`/${institutionId}`);
  }
}