<div class="wrapper">
  <!-- Cabeçalho -->
  <app-header></app-header>

  <!-- Menu -->
  <app-menu></app-menu>

  <!-- Conteúdo da página -->
  <router-outlet></router-outlet>

  <!-- <app-sidenav>
    <router-outlet></router-outlet>
  </app-sidenav> -->

  <aside class="control-sidebar control-sidebar-dark">
  <!-- Control sidebar content goes here -->
  dusahduasdh
  dhuasdha
  udahsudha
  </aside>

  <!-- Rodapé-->
  <app-footer></app-footer>
</div>
