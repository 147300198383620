import { InstitutionService } from 'src/app/components/shared/services/institution.service';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { BaseResourceFormComponent } from '../../base-resource-form/base-resource-form.component';
import { TokenService } from './../../../../../core/auth/services/token.service';
import { switchMap } from 'rxjs/operators';
import { AgentService } from 'src/app/components/admin/partners/services/partner.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subscription, of } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent extends BaseResourceFormComponent implements OnInit {

  protected createFrom(): void {

  }

  userLogged: string;
  userPhotoProfile: string;
  roleSession: string;
  logoIntitution: string;
  userId: string;
  pages = [];
  isPartner = false;
  agentSubscription: Subscription | undefined;

  constructor(
    public router: Router,
    injector: Injector,
    public tokenService: TokenService,
    private agentService: AgentService,
    private institutionService: InstitutionService
  ) {
    super(injector);
  }

  ngOnInit(): void {

    this.roleSession = this.tokenService.getToken(this.tokenService.TOKEN_ROLE);
    this.logoIntitution = this.tokenService.getToken(this.tokenService.TOKEN_INTITUTION_LOGO);
    this.userId = this.tokenService.getToken(this.tokenService.TOKEN_USER_ID);
    this.pages = JSON.parse(sessionStorage.getItem('pages'));

    const couponMenu = this.tokenService.getToken(this.tokenService.COUPON_MENU);
    if (couponMenu === "false") {
      this.pages = this.pages.filter(page => page.url !== 'admin/coupon');
    }

    $('[data-widget="treeview"]').Treeview('init');

    if (!this.pages || this.pages.length === 0) {
      this.institutionService.getPages().subscribe((pages: any) => {
        sessionStorage.setItem('pages', JSON.stringify(pages.data));
        this.pages = pages.data;
      });
    }

    this.agentSubscription = this.agentService.agent.pipe(
      switchMap((agentId) => {
        const id = agentId ?? JSON.parse(this.tokenService.getToken(this.tokenService.AGENT))?.id;
        if (['AGENT'].includes(this.tokenService.getToken(this.tokenService.TOKEN_ROLE))) {
          return this.agentService.getAgency(id);
        } else {
          return of(null); // Return a stream that emits null
        }
      })
    ).subscribe((result) => {
      if (result) {
        this.isPartner = true;
        // [nome e foto da agencia]
        this.userLogged = result.data.agencyName;
        this.userPhotoProfile = result.data.profilePictureUrl;

        // [nome e foto do perfil]
        // this.userLogged = this.tokenService.getToken('userName');
        // this.userPhotoProfile = this.tokenService.getToken(this.tokenService.TOKEN_IMAGE_PROFILE);
      } else {
        this.userLogged = this.tokenService.getToken('userName');
        this.userPhotoProfile = this.tokenService.getToken(this.tokenService.TOKEN_IMAGE_PROFILE);
      }
    });
  }

  navegar(link: string): void {
    if (link) {
      this.router.navigate([link]);
    }
  }

  navegarClient(): void {
    this.router.navigate([`admin/clients/${this.tokenService.getToken(this.tokenService.TOKEN_PERSON_ID)}`]);
  }

  visibleComponent(roles: string[]): boolean {
    return roles.includes(this.roleSession);
  }

  ngOnDestroy(): void {
    this.agentSubscription?.unsubscribe()
  }
}

