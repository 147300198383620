<div class="row">
  <div class="col-sm-12">
    <div class="card mb-4 p-sm-3 ml-1 mr-1">

      <div class="card-header p-0 m-0">
        <div class="d-flex w-100" style="text-align: start;">
          <p class="label-hotel-name font-weight-bold m-0">{{ hotel.name }}</p>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center w-100">
          <p class="label-hotel-address font-weight-bold m-0 app-grey">
            <i class="pe-7s-map-marker"></i> {{ hotel.address.addressLine }}
          </p>
          <div class="cat_star ml-auto">
            <i class="fas fa-star" *ngFor="let star of numberArray(hotel.stars)"></i>
          </div>
        </div>
      </div>


      <div class="card-body p-0" *ngIf="images.length > 5">
        <div class="container-gallery d-xl-flex d-lg-flex d-md-flex d-sm-none d-xs-none">
          <div class="row p-0">
            <div class="col-sm pl-3">
              <ngb-carousel #carousel [interval]="4000" [showNavigationArrows]="true" [showNavigationIndicators]="true"
                style="z-index: 9;" class="carousel-gallery">
                <ng-container *ngFor="let image of images; let i = index">
                  <ng-template ngbSlide>
                    <img [src]="image.publicUrl" class="img-fluid" alt=""
                      style="max-width: 100%; height: 260px; object-fit: cover; -webkit-object-fit: cover; border-radius: 8px;">
                    <div class="carousel-caption pb-0" style="color: #ccc;">
                      <span class="icon-acoes ml-2" (click)="activateDeactivate(image)" *ngIf="!image.fileName">
                        <i class="fas fa-3x cursor-pointer" [class.fa-eye]="image.isActive"
                          [class.fa-eye-slash]="!image.isActive" [tooltip]="defineTooltip(image.isActive)"></i>
                      </span>
                    </div>
                  </ng-template>
                </ng-container>
              </ngb-carousel>
            </div>
            <div class="col-sm p-0">
              <div class="row p-0">
                <div class="col-7 pt-0 pl-1 pr-1 pb-1">
                  <img [src]="fixedDesktop[1]?.publicUrl" class="img-fluid" alt=""
                    style="height: 125px; width: 100%; border-radius: 8px; object-fit: cover; -webkit-object-fit: cover;">
                </div>
                <div class="col-5 pt-0 pl-0 pr-1 pb-0">
                  <img [src]="fixedDesktop[2]?.publicUrl" class="img-fluid" alt=""
                    style="height: 127px; width: 100%; border-radius: 8px; object-fit: cover; -webkit-object-fit: cover;">
                </div>
                <div class="w-100"></div>
                <div class="col-5 pt-1 pl-1 pr-1 pb-0">
                  <img [src]="fixedDesktop[3]?.publicUrl" class="img-fluid" alt=""
                    style="height: 127px; width: 100%; border-radius: 8px; object-fit: cover; -webkit-object-fit: cover;">
                </div>
                <div class="col-7 pt-1 pl-1 pr-1 pb-0">
                  <img [src]="fixedDesktop[4]?.publicUrl" class="img-fluid" alt=""
                    style="height: 125px; width: 100%; border-radius: 8px; object-fit: cover; -webkit-object-fit: cover;">
                </div>
              </div>
            </div>
            <div class="col-sm pr-3">
              <img [src]="images[0]?.publicUrl" class="img-fluid" alt=""
                style="max-width: 100%; height: 260px; border-radius: 8px; object-fit: cover; -webkit-object-fit: cover;">
            </div>
          </div>
        </div>

        <div class="row d-xl-flex d-lg-flex d-md-flex d-sm-none d-xs-none">

          <div class="col-sm-4 mt-sm-4 mb-3 d-flex flex-column justify-content-center align-items-start">
            <div class="d-flex flex-row justify-content-between w-100">
              <div>
                <p class="mb-0 app-f22 font-weight-bold text-left">Casal/Single</p>
              </div>
            </div>

            <div class="d-flex flex-row w-100">
              <p class="mb-0 app-f14 font-weight-500 app-blue">Acomoda 2 Adulto(s) e 1 Criança(s)</p>
            </div>

            <div class="d-flex flex-row justify-content-between w-100 mt-3">
              <div class="col">
                <div class="row">
                  <div class="border-box-blue app-btn-round-8 app-f14 font-weight-500 w-50 text-center">1 quarto</div>
                </div>
                <div class="row mt-1">
                  <div class="border-box-orange app-btn-round-8 app-f14 font-weight-500 w-50 text-center">
                    Café da manhã
                  </div>
                </div>
                <div class="row mt-2">
                  <label class="app-f16 font-weight-500 app-blue mt-1 app-pointer">
                    Ver mais
                    <i class="fas fa-chevron-down"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-4 pl-5 mt-sm-4 mb-3 d-flex flex-column justify-content-center align-items-start">
            <div class="row">
              <p class="mb-0 app-f24">
                <span class="font-weight-bold">R$ 84,00</span>
              </p>
            </div>
            <div class="row">
              <label class="app-f14 font-weight-normal app-black m-0">
                por noite/por quarto
              </label>
            </div>
            <div class="row">
              <p class="mb-0 app-f14 mt-1">
                Taxas e impostos inclusos
              </p>
            </div>
            <div class="app-divider"></div>
            <div class="row text-left">
              <p class="mb-0 app-f24">
                <span class="font-weight-bold app-f16">
                  R$ 252,00
                </span>
                <br>
                para
                <span class="app-blue font-weight-bold">
                  3 noites
                </span>
              </p>
            </div>
          </div>

          <div class="col-sm-4 mt-sm-4 mb-3 d-flex flex-column justify-content-end align-items-start">
            <div class="d-flex flex-column justify-content-center align-items-center w-100 mb-3">
              <label class="app-f16 font-weight-normal app-orange text-right text-decoration-underline app-pointer">
                Reembolsável <i class="fas fa-exclamation-circle"></i>
              </label>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center w-100">
              <button class="app-btn w-100 app-f18 font-weight-bold p-0 app-h40">
                Escolher outro quarto
              </button>
              <button class="app-btn mt-2 app-btn-orange w-100 app-f18 font-weight-bold p-0 app-h40">
                Reservar essa opção
              </button>
            </div>
          </div>

          <div class="w-100"></div>

        </div>

      </div>
    </div>
  </div>