<div class="modal-container">
  <div class="modal-header">
    <h4 class="modal-title">{{ item.description }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-body--float d-flex justify-content-center">


      <img [src]="item.url" alt="Imagem Expandida" class="img-fluid">


  </div>
</div>