<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" *ngIf="!agentComissions">
    {{ message }}
</div>
<div class="modal-body" *ngIf="agentComissions">

    <div class="row">
        <div class="col-12">
            <h5>{{'comissions-bonus-agent.title.bonus' | translate}}</h5>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <div><span style="font-size: x-large;">{{'comissions-bonus-agent.label.hotel' | translate}}</span></div>
                                    <div><span>{{'comissions-bonus-agent.label.bonus-for' | translate}} {{comissions?.hotelComission.value}}%</span></div>
                                </div>
                                <div>
                                    <img src="assets/img/comission-hotel.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <div><span style="font-size: x-large;">{{'comissions-bonus-agent.label.air' | translate}}</span></div>
                                    <div><span>{{'comissions-bonus-agent.label.bonus-for' | translate}} {{comissions?.fligthComission.value}}%</span></div>
                                </div>
                                <div>
                                    <img src="assets/img/comission-plane.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <div><span style="font-size: x-large;">{{'comissions-bonus-agent.label.insurance' | translate}}</span></div>
                                    <div><span>{{'comissions-bonus-agent.label.bonus-for' | translate}} {{comissions?.insuranceComission.value}}%</span></div>
                                </div>
                                <div>
                                    <img style="float:right" src="assets/img/health_and_safety.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <h5>{{'comissions-bonus-agent.title.extra' | translate}}</h5>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <div><span style="font-size: x-large;">{{'comissions-bonus-agent.label.hotel' | translate}}</span></div>
                                    <div><span>{{'comissions-bonus-agent.label.bonus-for' | translate}} {{agentComissions?.hotelValue}}%</span></div>
                                </div>
                                <div>
                                    <img src="assets/img/comission-hotel.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <div><span style="font-size: x-large;">{{'comissions-bonus-agent.label.air' | translate}}</span></div>
                                    <div><span>{{'comissions-bonus-agent.label.bonus-for' | translate}} {{agentComissions?.fligthValue}}%</span></div>
                                </div>
                                <div>
                                    <img src="assets/img/comission-plane.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <div><span style="font-size: x-large;">{{'comissions-bonus-agent.label.insurance' | translate}}</span></div>
                                    <div><span>{{'comissions-bonus-agent.label.bonus-for' | translate}} {{agentComissions?.insuranceValue}}%</span></div>
                                </div>
                                <div>
                                    <img style="float:right" src="assets/img/health_and_safety.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div *ngIf="!noCancel">
        <button type="button" class="btn btn-admin btn-secondary" (click)="decline()">Cancel</button>
    </div>
    <button type="button" class="btn btn-primary btn-admin btn-primary-admin" (click)="accept()">OK</button>
</div>