<body class="hold-transition login-page" style="background-color: #343a40;">

  <div class="login-box">
    <div class="flex-column space-even">
      <!-- <div style="display: flex; justify-content: center;">
        <img src="../../../../assets/img/logo.png" alt="Ōner Área Administrativa" class="brand-image"
          style="opacity: .8">
      </div> -->
            <div class="login-logo">
                <a href="#" style="color: #fff">
                    <b>{{'login.title' | translate}}</b>
                </a>
            </div>
        </div>
        <div class="break-row-100"></div>
        <div class="card">
            <div class="card-body login-card-body">
                <p class="login-box-msg">{{'login.label.title' | translate}}</p>
                <form novalidate [formGroup]="form">
                    <div class="input-group mb-3">
                        <input type="email" formControlName="login" class="form-control blend-right" placeholder="{{'login.label.email' | translate}}" name="login">
                        <div class="input-group-append">
                            <div class="input-group-text blend-left">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <div class="input-group">
                        <input type="password" formControlName="password" class="form-control blend-right" placeholder="{{'login.label.password' | translate}}" name="password">
                        <div class="input-group-append">
                            <div class="input-group-text blend-left">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div class="reset-pass mr-3 mt-2" (click)="resetPassword()">
                        <small>{{'login.label.forgot.password' | translate}}</small>
                    </div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-10 mt-3">
                            <button type="submit" class="btn btn-primary btn-block option-button" (click)="login()">{{'login.button.getin' | translate}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</body>
