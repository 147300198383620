import { Directive, ElementRef, HostListener, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appNoSpecialChars]'
})
export class NoSpecialCharsDirective {
    constructor(private el: ElementRef<HTMLInputElement>,
        @Optional() @Self() private ngControl: NgControl) { }



    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {

        const navigationKeys = [
            'Backspace', 'Delete', 'Tab', 'Escape', 'Enter',
            'Home', 'End', 'ArrowLeft', 'ArrowRight'
        ];

        if (navigationKeys.includes(event.key)) {
            return;
        }

        const isSpecialChar = /['"`*&ˆ%$#@!~`<>:;,.\/\\\[\](){}]/.test(event.key);
        if (isSpecialChar) {
            event.preventDefault();
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent): void {

        const pastedData = event.clipboardData?.getData('text/plain') || '';

        const sanitizedData = pastedData.replace(/['"`*&ˆ%$#@!~`<>:;,.\/\\\[\](){}]/g, '');

        const inputValue = this.el.nativeElement.value;
        const start = this.el.nativeElement.selectionStart ?? 0;
        const end = this.el.nativeElement.selectionEnd ?? 0;
        this.el.nativeElement.value = inputValue.slice(0, start) + sanitizedData + inputValue.slice(end);
        event.preventDefault(); 

        if (this.ngControl && this.ngControl.control) {
            this.ngControl.control.setValue(sanitizedData);
        }
    }

    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent): void {
        event.preventDefault(); 
        const textData = event.dataTransfer?.getData('text/plain') || '';
        
        const sanitizedData = textData.replace(/['"`*&ˆ%$#@!~`<>:;,.\/\\\[\](){}]/g, '');

        const inputValue = this.el.nativeElement.value;
        const start = this.el.nativeElement.selectionStart ?? 0;
        const end = this.el.nativeElement.selectionEnd ?? 0;
        this.el.nativeElement.value = inputValue.slice(0, start) + sanitizedData + inputValue.slice(end);
    }
}
