<div>
  <div class="mt-3rem cia-name">
    {{ "confirm-modal-delete.warn-message" | translate }}
  </div>
  <div class="mt-3rem">
    <button
      id="add-or-update"
      type="button"
      class="btn btn-primary float-right space-button"
      [mat-dialog-close]="false" cdkFocusInitial
    >
      {{ "general.button.title.cancel" | translate }}
    </button>
    <button
      id="cancel"
      class="btn btn-secondary mr-2 float-right space-button"
      type="submit"
      [mat-dialog-close]="true" cdkFocusInitial
    >
      {{ "general.button.title.delete" | translate }}
    </button>
  </div>
</div>
