import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currency' })
export class CurrencyFormatPipe implements PipeTransform {

  constructor() { }

  transform(value: any | string, currencyCode: string = 'BRL', symbol?: string): string {
    let valueFormated: any = '';

    switch (currencyCode) {
      // BRL
      case 'BRL':
        const currencyPtBr: CurrencyPipe = new CurrencyPipe('pt-BR');
        valueFormated = currencyPtBr.transform(value, currencyCode, 'R$', symbol);
        break;
      case 'USD':
        const currencyEn: CurrencyPipe = new CurrencyPipe('en-us');
        valueFormated = currencyEn.transform(value, currencyCode, '$', symbol);
        break;
      case 'EUR':
        const currencyEu: CurrencyPipe = new CurrencyPipe('fr-FR');
        valueFormated = currencyEu.transform(value, currencyCode, '€', symbol);
        break;
      default:
        valueFormated = value;
        break;
    }
    return valueFormated;
  }
}
