export default class Languages {

  // Messages ERROR
  public static readonly ENGLISH: Language = constructorLanguage(1, 'English');
  public static readonly PORTUGUESE: Language = constructorLanguage(2, 'Portuguese');
  public static readonly SPANISH: Language = constructorLanguage(3, 'Spanish');
  public static readonly BR_PORTUGUESE: Language = constructorLanguage(4, 'Portuguese Brazil');
  public static readonly ITALIAN: Language = constructorLanguage(5, 'Italian');
  public static readonly GERMAN: Language = constructorLanguage(6, 'German');
  public static readonly FRENCH: Language = constructorLanguage(7, 'French');
  public static readonly MX_SPANISH: Language = constructorLanguage(8, 'Spanish (México)');
  public static readonly US_ENGLISH: Language = constructorLanguage(9, 'English (United States)');

  public static readonly ALL_LANGUAGES: Language[] = [
    Languages.ENGLISH, Languages.PORTUGUESE,
    Languages.SPANISH, Languages.BR_PORTUGUESE,
    Languages.ITALIAN, Languages.GERMAN, Languages.FRENCH,
    Languages.MX_SPANISH, Languages.US_ENGLISH,
  ]

}

function constructorLanguage(id: number, name: string): Language {
  return {id, name};
}

class Language {
  id: number;
  name: string;
}
