<mat-sidenav-container class="sidenav-container mat-app-background" [ngClass]="{'dark-theme-mode': isDarkTheme}">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">

    <mat-toolbar color="primary" class="tittle icon-mobile" *ngIf="!(isHandset$ | async)">

    </mat-toolbar>
    <mat-toolbar color="primary" class="tittle icon-mobile" *ngIf="isHandset$ | async">

    </mat-toolbar>

    <mat-nav-list *ngIf="true">
      <ng-container *ngFor="let item of pages">
        <a mat-list-item class="tab-link tittle-menu" (click)="navegar(item.url); drawer.toggle()" *ngIf="isHandset$ | async">
          <i class="{{item.icon}}"></i>
          <p>{{item.title | translate}}</p>
        </a>
        <a mat-list-item class="tab-link tittle-menu" (click)="navegar(item.url)" *ngIf="!(isHandset$ | async)">
          <i class="{{item.icon}}"></i>
          <p>{{item.title | translate}}</p>
        </a>
      </ng-container>
    </mat-nav-list>

  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <span class="tittle"></span>
      <span class="example-spacer"></span>
      <!-- <h1>{{ user?.firstName | uppercase }}</h1> -->
      <!-- <button mat-icon-button (click)="storeThemeSelected()">
                <mat-icon *ngIf="!isDarkTheme">dark_mode</mat-icon>
                <mat-icon *ngIf="isDarkTheme">light_mode</mat-icon>
            </button> -->
      <!-- <button mat-icon-button (click)="onLogout()">
                <mat-icon class="signout" aria-hidden="false" aria-label="signout">logout</mat-icon>
            </button> -->
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
