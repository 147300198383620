<body class="hold-transition login-page" style="background-color: #343a40;">
    <div class="login-box">
        <div class="card">
            <div class="card-body login-card-body">

                <div class="card-head">
                    <div class="title-login">
                        {{'login.label.title.resetpassword' | translate}}
                    </div>
                </div>

                <form novalidate [formGroup]="form" class="form">
                  <div class="input-form">
                        <input style="height:50px;" type="email" formControlName="login" class="form-control" placeholder="{{'login.label.email' | translate}}" name="login">
                    </div>
                    <div class="row mt-3">
                        <div class="col-6">
                            <button type="submit" class="btn btn-light btn-block" (click)="back()">{{'general.button.title.cancel' | translate}}</button>
                        </div>

                        <div class="col-6">
                            <button type="submit" class="btn btn-primary btn-block" (click)="reset()">{{'general.button.title.send' | translate}}</button>
                        </div>

                    </div>
                </form>

                <div class="row d-flex justify-content-center">
                    <div class="col-12 area-adm">
                        {{ 'authentication.restric-area' | translate }}
                    </div>
                </div>

            </div>

        </div>
    </div>
</body>
