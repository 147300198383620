import { HomeInformationModule } from './home-information/home-information.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLanguageResolver } from 'src/app/resolver/app.language.resolver';
import { AuthGuard } from './../../core/guards/auth.guard';
import { AdminComponent } from './admin.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('../admin/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'admin/partners',
        loadChildren: () => import('./partners/partner.module').then(m => m.AgentModule)
      },
      {
        path: 'admin/profile',
        loadChildren: () => import('../admin/my-profile/my-profile.module').then(m => m.MyProfileModule)
      },
      {
        path: 'admin/comission-table',
        loadChildren: () => import('../admin/comission-table/comission-table.module').then(m => m.ComissionTableModule)
      },
      {
        path: 'admin/comissions',
        loadChildren: () => import('../admin/comissions/comissions.module').then(m => m.ComissionsModule)
      },
      {
        path: 'admin/campaigns',
        loadChildren: () => import('../admin/campaign/campaign.module').then(m => m.CampaignModule)
      },
      {
        path: 'admin/currency',
        loadChildren: () => import('../admin/currency/currency.module').then(m => m.CurrencyModule)
      },
      {
        path: 'admin/hotels',
        loadChildren: () => import('../admin/hotel/hotel.module').then(m => m.HotelModule)
      },
      {
        path: 'admin/bonus-rede',
        loadChildren: () => import('../admin/bonus-rede/bonus-rede.module').then(m => m.BonusRedeModule)
      },
      {
        path: 'admin/bonus-agent',
        loadChildren: () => import('../admin/bonus-agent/bonus-agent.module').then(m => m.BonusAgentModule)
      },
      {
        path: 'admin/clients',
        loadChildren: () => import('../admin/clients/clients.module').then(m => m.ClientsModule)
      },
      {
        path: 'admin/sales-institution',
        loadChildren: () => import('./sales-institution/sales-intitution.module').then(m => m.SalesInstitutionModule)
      },
      {
        path: 'admin/sales-agent',
        loadChildren: () => import('./sales-agent/sales-agent.module').then(m => m.SalesAgentModule)
      },
      {
        path: 'admin/sales',
        loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule)
      },
      {
        path: 'admin/customer-invoice',
        loadChildren: () => import('./customer-invoice/customer-invoice.module').then(m => m.CustomerInvoiceModule)
      },
      {
        path: 'admin/destinations',
        loadChildren: () => import('./destinies/destinies.module').then(m => m.DestiniesModule)
      },
      {
        path: 'admin/site',
        loadChildren: () => import('../admin/site/site.module').then(m => m.SiteModule)
      },
      {
        path: 'admin/coverimage',
        loadChildren: () => import('./cover-image/cover-image.module').then(m => m.CoverImageModule)
      },
      {
        path: 'admin/booking',
        loadChildren: () => import('./booking/booking.module').then(m => m.BookingModule)
      },
      {
        path: 'admin/booking-agent',
        loadChildren: () => import('./booking-agent/booking-agent.module').then(m => m.BookingAgentModule)
      },
      {
        path: 'admin/airport',
        loadChildren: () => import('./airport/airport.module').then(m => m.AirportModule)
      },
      {
        path: 'admin/airline',
        loadChildren: () => import('./airline/airline.module').then(m => m.AirlineModule)
      },
      {
        path: 'admin/destination',
        loadChildren: () => import('./destination/destination.module').then(m => m.DestinationModule)
      },
      {
        path: 'admin/cities',
        loadChildren: () => import('./cities/cities.module').then(m => m.CitiesModule)
      },
      {
        path: 'admin/offline-sales',
        loadChildren: () => import('./offline-sales/offline-sales.module').then(m => m.OfflineSalesModule)
      },
      {
        path: 'admin/points-interest',
        loadChildren: () => import('./points-interest/points-interest.module').then(m => m.PointsInterestModule)
      },
      {
        path: 'admin/offer-template-aggregator',
        loadChildren: () => import('./offer-template/offer-template.module').then(m => m.OfferTemplateModule)
      },
      {
        path: 'admin/bonus-demonstrative',
        loadChildren: () => import('./bonus-demonstrative/bonus-demonstrative.module').then(m => m.AgentInvoiceBonusModule)
      },
      {
        path: 'admin/bonus-demonstrative-partner',
        loadChildren: () => import('./bonus-demonstrative-partner/bonus-demonstrative-partner.module').then(m => m.ViewPartnerInvoiceBonusModule)
      },
      {
        path: 'admin/bonus-demonstrative-payment',
        loadChildren: () => import('./bonus-demonstrative-payment/bonus-demonstrative-payment.module').then(m => m.BonusDemonstrativePaymentModule)
      },
      {
        path: 'admin/marketing-book-offer',
        loadChildren: () => import('./marketing-book-offer/marketing-book-offer.module').then(m => m.MarketingBookOfferModule)
      },
      {
        path: 'admin/tag',
        loadChildren: () => import('./tag/tag.module').then(m => m.TagModule)
      },
      {
        path: 'admin/coupon',
        loadChildren: () => import('./coupon/coupon.module').then(m => m.CouponModule)
      },
      {
        path: 'admin/markup',
        loadChildren: () => import('./markup/markup.module').then(m => m.MarkupModule)
      },
      {
        path: 'admin/blacklist',
        loadChildren: () => import('./blacklist/blacklist.module').then(m => m.BlacklistModule)
      },
      {
        path: 'admin/homeinformation',
        loadChildren: () => import('./home-information/home-information.module').then(m => m.HomeInformationModule)
      },
      {
        path: 'admin/institution-configuration',
        loadChildren: () => import('./institution-configuration/institution-configuration.module').then(m => m.InstitutionConfigurationModule)
      },
      {
        path: 'admin/installment-company',
        loadChildren: () => import('./installment-by-company/installment-by-company.module').then(m => m.InstallmentCompany)
      },
      {
        path: 'admin/installment-rules',
        loadChildren: () => import('./installment-rules/installment-rules.module').then(m => m.InstallmentRulesModule)
      }
    ],
    resolve: { language: AppLanguageResolver }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
