import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injector } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ResultObjectModel } from './../../components/shared/components/base-resource-form/model/result-object.model';
import { ResultSearchModel } from './../../components/shared/components/base-resource-form/model/result-search.model';

export class BaseService<T, ID> {

  protected base: string;
  protected http: HttpClient;
  protected loaderService: NgxSpinnerService;

  protected httpOptions: {
    headers: HttpHeaders
  };

  constructor(protected injector: Injector, protected baseUrl: string,
    protected options?: { headers: HttpHeaders }
  ) {
    this.http = injector.get(HttpClient);
    this.loaderService = injector.get(NgxSpinnerService);
    this.base = `${environment.apiBaseUrl}`;
    if (!!this.baseUrl) {
      this.base += `${baseUrl}`;
    }

    if (!options) {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Platform: 'BACKOFFICE',
          InstitutionId: '1'
        })
      };
    } else {
      this.httpOptions = options;
    }
  }

  get(complementUrl?: string): Observable<T> {
    if (this.loaderService) {
      this.loaderService.show();
    }
    return this.http.get<T>(this.base + this.getComplementUrl(complementUrl), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getNoLoader(complementUrl?: string): Observable<T> {
    return this.http.get<T>(this.base + this.getComplementUrl(complementUrl), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getAll(complementUrl?: string): Observable<ResultObjectModel<T>> {
    if (this.loaderService) {
      this.loaderService.show();
    }
    return this.http.get<ResultObjectModel<T>>(this.base + this.getComplementUrl(complementUrl), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getAllPageable(queryString: any, complementUrl?: string): Observable<ResultSearchModel<T>> {
    if (this.loaderService) {
      this.loaderService.show();
    }
    return this.http.get<ResultSearchModel<T>>(
      `${this.base}${this.getComplementUrl(complementUrl)}?${queryString}`, this.options
    )
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  post(t: T, complementUrl?: string): Observable<ResultObjectModel<T>> {
    if (this.loaderService) {
      this.loaderService.show();
    }
    return this.http.post<ResultObjectModel<T>>(this.base + this.getComplementUrl(complementUrl), t, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  postNoLoader(t: T, complementUrl?: string): Observable<ResultObjectModel<T>> {
    return this.http.post<ResultObjectModel<T>>(this.base + this.getComplementUrl(complementUrl), t, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  postAny(t: any, complementUrl?: string): Observable<any> {
    if (this.loaderService) {
      this.loaderService.show();
    }
    return this.http.post<any>(this.base + complementUrl, t, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  put(id: ID, t: T, complementUrl?: string): Observable<T> {
    if (this.loaderService) {
      this.loaderService.show();
    }
    let complement = '';
    if (!!id) {
      complement = `${this.getComplementUrl(complementUrl)}/${id}`;
    } else {
      complement = this.getComplementUrl(complementUrl);
    }
    return this.http.put<T>(`${this.base}${complement}`, t, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  putAny(id: number, t: any, complementUrl?: string): Observable<any> {
    if (this.loaderService) {
      this.loaderService.show();
    }
    let complement = '';
    if (!!id) {
      complement = `${this.getComplementUrl(complementUrl)}/${id}`;
    } else {
      complement = this.getComplementUrl(complementUrl);
    }
    return this.http.put<T>(`${this.base}${complement}`, t, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  patch(id: ID, t: any, complementUrl?: string): Observable<T> {
    return this.http.patch<T>(`${this.base}${this.getComplementUrl(complementUrl)}${id != null ? '/' + id : ''}`, t, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  patchPI(id: ID, t: any, complementUrl?: string): Observable<T> {
    return this.http.patch<T>(`${this.base}/${id}${this.getComplementUrl(complementUrl)}/` + t, t, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  delete(id: ID): Observable<T> {
    if (this.loaderService) {
      this.loaderService.show();
    }
    return this.http.delete<T>(`${this.base}/${id}`, this.options)
      .pipe(
        retry(1),
        catchError(this.handleError));
  }

  findOne(id: ID): Observable<ResultObjectModel<T>> {
    return this.http.get<ResultObjectModel<T>>(`${this.base}/${id}`, this.options).pipe(
      retry(1),
      catchError(this.handleError));
  }

  findByGuid(guid: any): Observable<T> {
    return this.http.get<T>(`${this.base}/obter/${guid}`, this.options).pipe(
      retry(1),
      catchError(this.handleError));
  }

  findAll(): Observable<T[]> {
    return this.http.get<T[]>(this.base, this.options).pipe(
      retry(1),
      catchError(this.handleError));
  }

  search(queryString: any, complementUrl?: string): Observable<ResultSearchModel<T>> {
    if (this.loaderService) {
      this.loaderService.show();
    }
    return this.http.get<ResultSearchModel<T>>(
      `${this.base}${this.getComplementUrl(complementUrl)}/search?${queryString}`, this.options
    )
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  searchNoLoader(queryString: any, complementUrl?: string): Observable<ResultSearchModel<T>> {
    return this.http.get<ResultSearchModel<T>>(
      `${this.base}${this.getComplementUrl(complementUrl)}/search?${queryString}`, this.options
    )
      .pipe(
        catchError(this.handleError)
      );
  }

  private getComplementUrl(complementUrl: string): string {
    return complementUrl ? complementUrl : '';
  }

  protected handleError(err) {
    const errorMessage = err.message;
    // console.log(errorMessage);
    return throwError(err);
  }


}
