import { DOCUMENT } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/components/admin/points-interest/services/language.service';
import { BaseResourceFormComponent } from 'src/app/components/shared/components/base-resource-form/base-resource-form.component';
import Messages from 'src/app/components/shared/enums/messages.enum';
import { CurrencieService } from 'src/app/core/services/currencie.service';
import { AuthService } from './../../../../core/auth/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BaseResourceFormComponent implements OnInit {

  languages: any;

  constructor(
    injector: Injector,
    private authService: AuthService,
    private currencieService: CurrencieService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document
    ) {
    super(injector);
  }

  ngOnInit(): void {
    this.route.data.subscribe((resolve: any) => {
      this.languages = resolve?.language?.data;
      if (this.languages) {
        sessionStorage.setItem('languages', JSON.stringify(this.languages));
      }
    });

    const browserlang = navigator.language.toLowerCase();
    const langStorage = localStorage.getItem('lang') ?? browserlang;
    if (browserlang) {
      var currentLanguage = this.languages?.find(item => item.codeIso === langStorage);
      if (currentLanguage) {
        sessionStorage.setItem('currentLanguage', JSON.stringify(currentLanguage));
        this.translateService.setDefaultLang(langStorage);
        this.currencieService.setCurrencieByDefaultLanguage(langStorage);
        this.document.documentElement.lang = langStorage;
      } else {
        this.defaultLanguageIfNull();
      }
    } else {
      this.defaultLanguageIfNull();
    }
  }

  private defaultLanguageIfNull() {
    let en = 'en-us';
    this.document.documentElement.lang = en;
    this.translateService.setDefaultLang(en);
    this.currencieService.setCurrencieByDefaultLanguage(en);
    this.languageService.setLanguageId(this.languages?.find(item => item.codeIso === en).id);
  }

  createFrom(): void {
    this.form = this.formBuilder.group({
      login: [null, Validators.required]
    });
  }

  reset(): void {
    if (this.form.valid) {
      const login = this.form.value;
      // this.router.navigate(['admin']);
      this.authService.resetPassword(login).subscribe((result: any) => {
        this.notificationService.messageSuccess(this.translate.instant(Messages.LOGIN_SUCCESS_RESET_PASSWORD, {name: result}));
        this.back();
      }, (error: any) => {
        this.notificationService.messageError(error.error);
      });
    } else {
      this.notificationService.messageError(this.translate.instant(Messages.LOGIN_SUCCESS_RESET_PASSWORD));
    }

  }

  back(): void {
    this.router.navigate(['']);
  }



}
