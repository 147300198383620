import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'active'
})
export class ActivePipe implements PipeTransform {

    transform(value: string) {
    return value === 'S' ? 'Ativo' : 'Inativo';
  }

}
