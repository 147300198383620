import { Observable } from 'rxjs';
import { AuthService } from './../auth/services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.check()) {
      return true;
    }
    // this.authService.logout();
    this.router.navigate(['/login']);
    // console.log('Usuário não autenticado --> Efetuar login');
    return false;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.check()) {
      return true;
    }
    // this.authService.logout();
    this.router.navigate(['/login']);
    // console.log('Usuário não autenticado --> Efetuar login');
    return false;
  }
}
