<tr>
  <th *ngFor="let column of cols" scope="col" class="coluna" (click)="clickOrder(column)">
    <div class="flex-row align-center j-space-between">
      {{column.label | translate}}
      <i class="fas fa-chevron-up" *ngIf="column.name === lastColumn && hasMetodo"
      [class.icone]="column.name === lastColumn"
      [class.baixo]="order"></i>
    </div>
  </th>
  <th scope="col" *ngIf="actions" colspan="2">{{'general.table.actions' | translate}}</th>
</tr>
