import { TranslateLoader } from '@ngx-translate/core';
import { Observable, from } from 'rxjs';

interface System {
  import(request: string): Promise<any>;
}

declare var System: System;

export class WebpackTranslateLoader implements TranslateLoader {

  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }

}
