import { AgentService } from './../../../components/admin/partners/services/partner.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/components/admin/points-interest/services/language.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  httpOptions = {
    headers: new HttpHeaders({
      Platform: 'BACKOFFICE',
      InstitutionId: '1'
    })
  };

  adminOnerEmails = [
    'giovanna@onertravel.com',
    'mickael@onertravel.com',
    'mauricio@onertravel.com',
    'tati@onertravel.com',
    'ricardo@onertravel.com',
    'maria@onertravel.com',
    'eliemerson@onertravel.com',
    "jnascimento@befly.com.br",
    "rodrigo@onertravel.com"
    // 'admin@p2d.travel',
  ];

  constructor(
    private router: Router,
    private http: HttpClient,
    private tokenService: TokenService,
    private languageService: LanguageService,
    private agentService: AgentService
  ) {}

  public login(auth: any): Observable<any> {
    return this.http.post<any>(
      environment.apiAuthUrl + '/api/authenticate/backoffice',
      auth,
      this.httpOptions
    );
  }

  public resetPassword(reset: any): Observable<any> {
    const language = this.languageService.getCurrentLanguage();
    const languageId = language ? language.id.toString() : '1';
    this.httpOptions.headers = this.httpOptions.headers.set(
      'language',
      languageId
    );
    return this.http.post<any>(
      environment.apiAuthUrl + '/api/authenticate/forgot-password',
      reset,
      this.httpOptions
    );
  }

  public updatePassword(updatePassword: any): Observable<any> {
    return this.http.post<any>(
      environment.apiAuthUrl + '/api/authenticate/update-password',
      updatePassword,
      this.httpOptions
    );
  }

  public addImageProfile(id: string, userImage: any): Observable<any> {
    return this.http.patch<any>(
      environment.apiAuthUrl + '/api/users/update-image-profile/' + id,
      userImage,
      this.httpOptions
    );
  }

  public getLoggedPersonId(): string {
    return this.tokenService.getToken(this.tokenService.TOKEN_PERSON_ID);
  }

  public createUserSession(authToken: any, rememberUser: string = 'true', bookingIdToCartMarkup?: string, redirectComponent?: string): void {
    const tokenDecoded: any = this.tokenService.decodeToken(authToken);
    this.tokenService.createToken(this.tokenService.EMAIL, tokenDecoded.email);
    this.tokenService.createToken(
      this.tokenService.LOGIN_USER_TOKEN,
      authToken
    );
    this.tokenService.createToken(
      this.tokenService.LOGIN_IS_FIRST_ACCESS,
      tokenDecoded.IsFisrtAccess
    );
    this.tokenService.createToken(
      this.tokenService.CHANGE_PASSWORD,
      tokenDecoded.ChangePassword
    );
    this.tokenService.createToken(
      this.tokenService.TOKEN_FULL_NAME,
      tokenDecoded.FullName
    );
    this.tokenService.createToken(
      this.tokenService.TOKEN_PERSON_ID,
      tokenDecoded.PersonId
    );
    this.tokenService.createToken(
      this.tokenService.TOKEN_USER_ID,
      tokenDecoded.UserId
    );
    this.tokenService.createToken(
      this.tokenService.TOKEN_IMAGE_PROFILE,
      tokenDecoded.ImageProfile
    );
    this.tokenService.createToken(
      this.tokenService.TOKEN_ROLE,
      tokenDecoded.role
    );
    this.tokenService.createToken(
      this.tokenService.TOKEN_INTITUTION_ID,
      tokenDecoded.InstitutionId
    );
    this.tokenService.createToken(
      this.tokenService.TOKEN_INTITUTION_LOGO,
      tokenDecoded.InstitutionLogo
    );
    this.tokenService.createToken(
      this.tokenService.TOKEN_INTITUTION_LOGO,
      tokenDecoded.InstitutionLogo
    );
    this.tokenService.createToken(
      this.tokenService.REMEMBER_USER,
      rememberUser
    );

    if (!this.tokenService.verifyTokenActive(this.tokenService.AGENT)) {
      this.agentService.getAgentByPerson(tokenDecoded.PersonId).subscribe(
        (agent: any) => {
          this.tokenService.createToken(
            this.tokenService.AGENT,
            JSON.stringify(agent.data)
          );
        },
        (err) => console.log(err)
      );
    }

    if (redirectComponent) {
      switch (redirectComponent) {
        case 'profile':
          this.router.navigate([`/admin/profile/company`]);
          break;

        case 'contract':
          this.router.navigate([`/admin/profile/contract`]);
          break;

        case 'personalization':
          this.router.navigate([`/admin/site/data`]);
          break;

        case 'commission':
          this.router.navigate([`/admin/comissions/bonus`]);
          break;

        case 'cart':
          this.router.navigate([`/admin/booking-agent`]);
          break;

        case 'sale':
          this.router.navigate([`/admin/sales-agent`]);
          break;

        case 'cupon':
          this.router.navigate([`/admin/coupon`]);
          break;

        default:
          this.router.navigate(['/admin']);
          break;
      }
    } else {
      if (bookingIdToCartMarkup) {
        this.router.navigate([`/admin/booking-agent/${bookingIdToCartMarkup}/cart-markup`]);
      } else {
        this.router.navigate(['/admin']);
      }
    }
  }

  public logout() {
    this.tokenService.removeToken();
  }

  public check(): boolean {
    return this.tokenService.verifyTokenExpired(
      this.tokenService.getToken('userToken')
    );
  }

  public sendBackofficeCode(email: any) {
    this.setLanguageToHeaders();
    return this.http.post(
      environment.apiAuthUrl + `/api/authenticate/send-backofficecode`,
      email,
      this.httpOptions
    );
  }

  public authenticationCode(code: any) {
    this.setLanguageToHeaders();
    return this.http.post(
      environment.apiAuthUrl + `/api/authenticate/code-backoffice`,
      code,
      this.httpOptions
    );
  }

  private setLanguageToHeaders(): any {
    const language = this.languageService.getCurrentLanguage();
    const languageId = language ? language.id.toString() : '1';
    this.httpOptions.headers = this.httpOptions.headers.set(
      'language',
      languageId
    );
  }

  public masterAccess(): boolean {
    const thereAccess = this.adminOnerEmails.includes(
      this.tokenService.getToken(this.tokenService.EMAIL)
    );

    return thereAccess;
  }
}
