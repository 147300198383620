import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { BookingService } from 'src/app/components/admin/booking/services/booking.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-voucher-resend',
  templateUrl: './voucher-resend.component.html',
  styleUrls: ['./voucher-resend.component.css']
})
export class VoucherResendComponent implements OnInit {

  @Input() title: string;
  @Input() bookingId: any;
  form: UntypedFormGroup;
  formSubmitted = false;
  message: string;
  voucherNotFound: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private bookingService: BookingService,
    public translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      recipients: this.fb.array([]),
      sendToAgent: [{ value: true, disabled: false }],
      sendToClient: [{ value: true, disabled: false }]
    })
  }

  createItem() {
    return this.fb.group({
      email: ['', [Validators.required]]
    })
  }

  addNext() {
    (this.form.controls['recipients'] as UntypedFormArray).push(this.createItem())
    this.formSubmitted = false;
  }

  removeItem(index: number) {
    (this.form.controls['recipients'] as UntypedFormArray).removeAt(index);
    this.formSubmitted = false;
  }

  submit() {
    this.formSubmitted = true;
    if (!this.form.valid) {
      return;
    }

    this.formSubmitted = false;
    const formulario: any = this.form.getRawValue();
    const obj = {
      recipients: [],
      sendToAgent: formulario.sendToAgent,
      sendToClient: formulario.sendToClient
    }
    formulario.recipients.forEach((element) => {
      obj.recipients.push(element.email);
    });

    this.bookingService.voucherResend(obj, this.bookingId).subscribe((response) => {
      const resp: any = response;
      if (resp?.data == 'VoucherNotFound') {
        this.message = 'O voucher ainda não esta disponível para esta venda. Tente novamente mais tarde.';
        this.voucherNotFound = true;
      } else {
        this.bsModalRef.content.callback(true);
      }
    });
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(false);
  }
}
