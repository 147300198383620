<div class="modal-header">
    <h4 class="modal-title">{{'voucher-resend.emailFrom' | translate}} {{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="d-flex flex-column" *ngIf="!voucherNotFound">
        <form [formGroup]="form" (ngSubmit)="submit()">

            <div class="col-12">

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" id="sendToAgent" formControlName="sendToAgent">
                                <label for="sendToAgent" class="custom-control-label">
                                    {{'voucher-resend.toAgent' | translate}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" id="sendToClient" formControlName="sendToClient">
                                <label for="sendToClient" class="custom-control-label">
                                    {{'voucher-resend.toClient' | translate}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div formArrayName="recipients" *ngFor="let item of form.get('recipients')['controls']; let i = index">
                    <div [formGroupName]="i">
                        <div class="form-group">
                            <label for="email">Email {{ i + 1}}</label>
                            <div class="d-flex flex-row">
                                <input formControlName="email" name="email" id="email" class="form-control" [ngClass]="{'ng-touched': formSubmitted}">
                                <button type="button" class="btn btn-danger ml-1" style="border-radius: 50%;" (click)="removeItem(i)">X</button>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" class="btn btn-admin btn-secondary" (click)="addNext()">{{'voucher-resend.add' | translate}}</button>
            </div>

        </form>
    </div>

    <div class="row" *ngIf="voucherNotFound">
        <div class="col-12">
            <div class="alert alert-warning" role="alert">
                {{message}}
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-admin btn-secondary" (click)="dismiss()">{{'voucher-resend.cancel' | translate}}</button>
    <button type="button" class="btn btn-primary btn-admin btn-primary-admin" (click)="submit()" *ngIf="!voucherNotFound">{{'voucher-resend.send' | translate}}</button>
</div>
