import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import customIcon from '../../../../../assets/custom-icon.json';

@Component({
  selector: 'app-custom-icon',
  templateUrl: './custom-icon.component.html',
  styleUrls: ['./custom-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomIconComponent implements OnInit {

  iconList = {};
  @Input() name: string;
  @Input() size: string;

  renderIcon: string;

  constructor() {
    this.iconList = customIcon;
  }

  ngOnInit(): void {
    const iconSizeH = `height='${this.size}'`;
    const iconSizeW = `width='${this.size}'`;
    const result = this.iconList[this.name].svg
      .replace(/height=("[0-9]*")/, iconSizeH)
      .replace(/width=("[0-9]*")/, iconSizeW);
    this.renderIcon = result;
  }
}
