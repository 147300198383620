import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { BaseResourceFormComponent } from '../../shared/components/base-resource-form/base-resource-form.component';
import Messages from '../../shared/enums/messages.enum';
import { InstitutionService } from '../../shared/services/institution.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalTermsConditionsComponent } from '../../shared/components/modal-terms-conditions/modal-terms-conditions.component';
import * as dayjs from 'dayjs';
import { TranslateService } from '@ngx-translate/core';
import { CurrencieService } from 'src/app/core/services/currencie.service';
import { LanguageService } from '../../admin/points-interest/services/language.service';
import { DOCUMENT } from '@angular/common';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { Configuration } from '../../shared/models/configuration';
import { AgentService } from '../../admin/partners/services/partner.service';
import { ModalEndTermComponent } from '../../shared/components/modal-end-term/modal-end-term.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseResourceFormComponent implements OnInit {

  modalRef: BsModalRef;
  languages: any;
  constructor(injector: Injector,
    private authService: AuthService,
    private loaderService: NgxSpinnerService,
    private institutionService: InstitutionService,
    private modalService: BsModalService,
    private currencieService: CurrencieService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private configurationService: ConfigurationService,
    private agentService: AgentService,
    @Inject(DOCUMENT) private document: Document) {
    super(injector);
  }

  ngOnInit(): void {

    const hasUserLocaStorage = this.authService.check();
    if (!hasUserLocaStorage) {
      if (this.tokenService.getToken(this.tokenService.REMEMBER_USER) === 'true') {
        this.authService.createUserSession(this.tokenService.getToken('userToken'));
      }
    }

    this.route.params.subscribe((res) => {
      if (res.jwt) {
        this.authService.createUserSession(res.jwt);
      }
    });
    this.resolve();
  }

  async resolve() {
    await this.route.data.subscribe((resolve: any) => {
      this.languages = resolve?.language?.data;
      if (this.languages) {
        sessionStorage.setItem('languages', JSON.stringify(this.languages));
      }
    });
    this.defaultLanguageBrowser();
  }

  defaultLanguageBrowser(): void {
    const browserlang = navigator.language.toLowerCase();
    const langStorage = localStorage.getItem('lang') ?? browserlang;
    if (browserlang) {
      var currentLanguage = this.languages?.find(item => item.codeIso === langStorage);
      if (currentLanguage) {
        sessionStorage.setItem('currentLanguage', JSON.stringify(currentLanguage));
        this.translateService.setDefaultLang(langStorage);
        this.currencieService.setCurrencieByDefaultLanguage(langStorage);
        this.document.documentElement.lang = langStorage;
      } else {
        this.defaultLanguageIfNull();
      }
    } else {
      this.defaultLanguageIfNull();
    }
  }

  private defaultLanguageIfNull() {
    let en = 'en-us';
    this.document.documentElement.lang = en;
    this.translateService.setDefaultLang(en);
    this.currencieService.setCurrencieByDefaultLanguage(en);
    this.languageService.setLanguageId(this.languages?.find(item => item.codeIso === en).id);
  }

  createFrom(): void {
    this.form = this.formBuilder.group({
      login: [null, Validators.required],
      password: [null, Validators.required]
    });
  }

  resetPassword(): void {
    this.router.navigate(['/login/reset-password']);
  }

  login(): void {
    if (this.form.valid) {
      const usuario = this.form.value;
      this.loaderService.show();

      const dateTimezone = dayjs(new Date());
      usuario.dateTimeClient = dateTimezone.format();

      this.authService.login(usuario).subscribe((result: any) => {
        this.authService.createUserSession(result.data.token);
        this.getPages();
        this.getConfigurations();
        this.loaderService.hide();
        this.checkTermsAndConditions(result.data.token);
        // this.checkEndTerm();
      }, (error: any) => {
        this.notificationService.messageError(this.translate.instant(Messages.LOGIN_ERROR_USER_PASSWORD));
        this.loaderService.hide();
      });
    } else {
      this.notificationService.messageError(this.translate.instant(Messages.REQUIRED_FIELDS));
    }
  }

  getPages(): void {
    this.institutionService.getPages().subscribe((pages: any) => {
      sessionStorage.setItem('pages', JSON.stringify(pages.data));
    });
  }

  checkTermsAndConditions(authToken: any): void {
    const tokenDecoded: any = this.tokenService.decodeToken(authToken);
    if (tokenDecoded.role === 'AGENT' && tokenDecoded.AcceptedTermsAndContract === 'False') {
      this.openModalTermsConditions();
    }
  }

  checkEndTerm(): void {
    this.agentService.getEndTerm().subscribe((result: any) => {
      console.log(result);
      if (result.data === false) {
        this.openEndTermModal();
      }
    });
  }

  openModalTermsConditions(): void {
    this.modalRef = this.modalService.show(ModalTermsConditionsComponent,
      {
        backdrop: true,
        animated: true,
        ignoreBackdropClick: true,
        keyboard: false,
        initialState: {
          callback: (result: boolean) => {
            if (result) { }
          }
        }
      });
  }

  openEndTermModal(): void {
    this.modalRef = this.modalService.show(ModalEndTermComponent,
      {
        backdrop: true,
        animated: true,
        ignoreBackdropClick: true,
        keyboard: false,
        initialState: {
          callback: (result: boolean) => {
            if (result) {
              // console.log(result);
            }
          }
        }
      });
  }

  getConfigurations() {
    this.configurationService.getConfigurations()
      .subscribe(result => {
        const couponMenu = result.data.configurationsList.find((config: Configuration) => config.name === 'CouponEnabled');
        this.tokenService.createToken(this.tokenService.COUPON_MENU, couponMenu.value);
      });
  }
}
