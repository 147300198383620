<div id="sign-in-dialog" class="zoom-anim-dialog" style="padding: 1.5rem;">
  <div class="sign-in-wrapper mt-sm-4 mt-5">
    <div class="d-flex flex-row justify-content-center mt-3">
      <img src="assets/img/terms.svg" width="150px" alt="">
    </div>

    <div class="d-flex flex-row justify-content-center mt-3">
      <h5 class="app-f18 text-center">
        Declaro que fui informado e orientado sobre o encerramento das operações da Ōner Travel que ocorrerá 27/02/2023, bem como estou ciente e aceito que meu contrato de divulgador será rescindido na mesma data.<br>
        <a class="font-weight-bold" style="border: none;font-size: 20px;" href="https://oner-public-document.s3.amazonaws.com/terms/notificacao-recisao-oner.pdf" target="_blank">
          Notificação de recisão Ōner
        </a>
      </h5>
    </div>
    <div class="row mt-3">
      <div class="col-sm-12 d-flex justify-content-center align-items-center">
        <button type="button" class="btn btn-success w-50 font-weight-bold app-uppercase ml-1" (click)="onAcceptTerms()">
          Estou ciente!
        </button>
      </div>
    </div>
  </div>
</div>