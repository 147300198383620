import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-image-detail',
  templateUrl: 'modal-image-detail.component.html'
})

export class ModalImageDetailComponent implements OnInit {

  @Input() item: any;
  mobileDevice = false;

  constructor(
    public bsModalRef: BsModalRef,
    private breakpointObserver: BreakpointObserver
  ) {
    this.mobileDevice = false;

    this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
      this.mobileDevice = result.matches;
    });
  }

  ngOnInit() { }

  public dismiss(): void {
    this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
  }
}