import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import Messages from 'src/app/components/shared/enums/messages.enum';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public panelClass = ['snackbar-success'];
  public message = 'Save Successful.';
  public duration = 3000;
  public horizontalPosition: 'left' | 'start' | 'end' | 'right' | 'center' | undefined = 'right';
  public verticalPosition: 'bottom' | 'top' | undefined = 'top';

  constructor(private toastr: ToastrService, private _snackBar: MatSnackBar, private translate: TranslateService) { }

  messageSuccess(data = this.message, duration = this.duration, horizontalPosition = this.horizontalPosition, verticalPosition = this.verticalPosition, panelClass = ['snackbar-success']): void {
    this._snackBar.open(data, null, { horizontalPosition, verticalPosition, panelClass, duration });
  }

  messageError(data = this.message, duration = this.duration, horizontalPosition = this.horizontalPosition, verticalPosition = this.verticalPosition, panelClass = ['snackbar-error']): void {
    this._snackBar.open(data, null, { horizontalPosition, verticalPosition, panelClass, duration });
  }

  messageInfo(data = this.message, duration = this.duration, horizontalPosition = this.horizontalPosition, verticalPosition = this.verticalPosition, panelClass = ['snackbar-info']): void {
    this._snackBar.open(data, null, { horizontalPosition, verticalPosition, panelClass, duration });
  }

  messageWarn(data = this.message, duration = this.duration, horizontalPosition = this.horizontalPosition, verticalPosition = this.verticalPosition, panelClass = ['snackbar-warning']): void {
    this._snackBar.open(data, null, { horizontalPosition, verticalPosition, panelClass, duration });
  }

  messageResendCode(message: string): void {
    this.toastr.success(message, '', {
      timeOut: 3000,
      positionClass: 'toast-center-center',
      toastClass: 'toast-resend-code',
      progressBar: false,
      tapToDismiss: false
    });
  }


  handleErrors(error: HttpErrorResponse): void {
    const ocorreuUmErro = error?.error?.Message ? error.error.Message : this.translate.instant(Messages.ERROR_INTERNAL);
    switch (ocorreuUmErro) {
      case 'EMAIL-EXISTS':
        this.messageError(this.translate.instant(Messages.EMAIL_EXISTS));
        break;
      case 'DOCUMENT-EXISTS':
        this.messageError(this.translate.instant(Messages.DOCUMENT_EXISTS));
        break;
      case 'AGENCY-NAME-EXISTS':
        this.messageError(this.translate.instant(Messages.AGENCY_NAME_EXISTS));
        break;
      case 'DOCUMENT-NOT-ALLOWED':
        this.messageError(this.translate.instant(Messages.DOCUMENT_NOT_ALLOWED));
        break;
      case 'AGENCYNAME-NOT-ALLOWED':
        this.messageError(this.translate.instant(Messages.AGENCYNAME_NOT_ALLOWED));
        break;

      default:
        this.messageError(this.translate.instant(Messages.ERROR_INTERNAL));
        break;
    }
  }

}
