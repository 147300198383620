import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CurrencieService } from 'src/app/core/services/currencie.service';
import { LanguageService } from './points-interest/services/language.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.sass']
})
export class AdminComponent implements OnInit {

  languages: any;
  constructor(private route: ActivatedRoute,
    private currencieService: CurrencieService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.resolve();
  }

  resolve(): void {
    this.route.data.subscribe((resolve: any) => {
      if (resolve?.language?.data) {
        this.languages = resolve?.language?.data;
        sessionStorage.setItem('languages', JSON.stringify(resolve?.language?.data));
        this.defaultLanguageBrowser(resolve?.language?.data)
      }
    });
  }


  defaultLanguageBrowser(languages): void {
    const browserlang = navigator.language.toLowerCase();
    const langStorage = localStorage.getItem('lang') ?? browserlang;
    if (langStorage) {
      var currentLanguage = languages?.find(item => item.codeIso === langStorage);
      if (currentLanguage) {
        this.languageService.setLanguageId(currentLanguage?.id);
        sessionStorage.setItem('currentLanguage', JSON.stringify(currentLanguage));
        this.translateService.setDefaultLang(langStorage);
        this.currencieService.setCurrencieByDefaultLanguage(langStorage);
        this.document.documentElement.lang = langStorage;
      } else {
        this.defaultLanguageIfNull();
      }
    } else {
      this.defaultLanguageIfNull();
    }
  }

  private defaultLanguageIfNull() {
    let en = 'en-us';
    this.document.documentElement.lang = en;
    this.translateService.setDefaultLang(en);
    this.currencieService.setCurrencieByDefaultLanguage(en);
    this.languageService.setLanguageId(this.languages?.find(item => item.codeIso === en).id);
  }
}
