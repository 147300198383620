import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseResourceFormComponent } from 'src/app/components/shared/components/base-resource-form/base-resource-form.component';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent extends BaseResourceFormComponent implements OnInit {

  constructor(
    private authService: AuthService,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {

    this.route.params.subscribe((res) => {
      if (res.jwt) {
        if (res.component) {
          this.authService.createUserSession(res.jwt, 'true', null, res.component);
        } else if (res.bookingId) {
          this.authService.createUserSession(res.jwt, 'true', res.bookingId, null);
        }
      }
    });

  }

  createFrom(): void {

  }
}
