<div class="modal-header">
  <h4 class="modal-title">
    {{ "modal-search-partner.title" | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="agentId">
            {{ "modal-search-partner.inputs.institution" | translate }}
          </label>
          <ng-select [items]="institutions" placeholder="{{ 'general.dropdown.select' | translate }}"
            [searchable]="true" formControlName="institutionId" labelForId="institutionId" bindLabel="institutionName"
            bindValue="id" class="app-input-rounded" (change)="changeInstitution()"
            [ngClass]="{ 'ng-touched': formSubmitted }">
            <ng-template ng-label-tmp let-item="item">
              <span>{{ item?.name }}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
              <span>{{ item?.name }}</span>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="agentId">
            {{ "modal-search-partner.inputs.agency" | translate }}
          </label>
          <input type="text" name="name" class="form-control" formControlName="agencyName"
            [ngClass]="{ 'ng-touched': formSubmitted }" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="personName">
            {{ "modal-search-partner.inputs.name" | translate }}
          </label>
          <input type="text" name="personName" class="form-control" formControlName="personName"
            [ngClass]="{ 'ng-touched': formSubmitted }" />
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="document">
            {{ "modal-search-partner.inputs.document" | translate }}
          </label>
          <input name="document" [attr.inputmode]="documentInputMode" class="form-control" formControlName="document"
            [ngClass]="{ 'ng-touched': formSubmitted }" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="email">
            {{ "modal-search-partner.inputs.email" | translate }}
          </label>
          <input type="text" name="email" class="form-control" formControlName="email"
            [ngClass]="{ 'ng-touched': formSubmitted }" />
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="phoneNumber">
            {{ "modal-search-partner.inputs.phone" | translate }}
          </label>
          <input type="text" name="phoneNumber" class="form-control" formControlName="phoneNumber" maxlength="15"
            [ngClass]="{ 'ng-touched': formSubmitted }" mask="{{ mobilePhoneMask }}" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="agencyDocument">
            CNPJ da loja
          </label>
          <input type="text" name="agencyDocument" class="form-control" formControlName="agencyDocument"
            [ngClass]="{ 'ng-touched': formSubmitted }" />
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="registerId">
            {{ "modal-search-partner.inputs.register-id" | translate }}
          </label>
          <input type="text" name="registerId" class="form-control" formControlName="agentId"
            [ngClass]="{ 'ng-touched': formSubmitted }" />
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div>
    <button type="button" class="btn btn-secondary" (click)="decline()">
      {{ "modal-search-partner.buttons.cancel" | translate }}
    </button>
  </div>
  <div *ngIf="enableForm">
    <button type="submit" name="submit" value="Submit" class="btn btn-primary" (click)="search()">
      {{ "modal-search-partner.buttons.search" | translate }}
    </button>
  </div>
</div>

<hr style="margin-top: 0px;" />

<div class="result-table m-3" *ngIf="page.rows?.length > 0">
  <div class="mb-3">
    <span>
      <strong>{{ page.totalItems }} {{ "modal-search-partner.modal-detail.storeFound" | translate }}</strong> - {{ "modal-search-partner.modal-detail.selectOne" | translate }}
    </span>
  </div>

  <div class="card">
    <div class="card-body table-responsive p-0">
      <table class="table table-hover text-nowrap">
        <thead app-consulta-colunas [cols]="cols" [actions]="true"></thead>
        <tbody>
          <tr *ngFor="let item of page.rows | paginate: page">
            <td>{{ item.agencyName }}</td>
            <td>{{ item.institution }}</td>
            <td>{{ item.candidatureStatusDescription }}</td>
            <td>{{ item.agentName }}</td>
            <td>{{ item.documentNumber }}</td>
            <td>{{ item.id }}</td>
            <td>
              <div class="flex-acoes d-flex justify-content-around">
                <span class="icon-acoes mr-2" (click)="openModalDetail(templateNested, item)">
                  <i class="fas fa-eye"></i>
                </span>
                <span class="icon-acoes" (click)="accept(item)">
                  <i class="fas fa-chevron-right" style="font-size: 22px;"></i>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer clearfix pagination">
      <pagination-controls class="float-right" (pageChange)="pageChanged($event)"
        [previousLabel]="paginationLabel.previousLabel" [nextLabel]="paginationLabel.nextLabel">
      </pagination-controls>
    </div>
  </div>
</div>

<div class="result-table m-3" *ngIf="page.rows?.length == 0">
  <div class="mb-3">
    <span>
      <strong>{{ "modal-search-partner.modal-detail.notFound" | translate }}</strong>
    </span>
  </div>
</div>

<ng-template #templateNested>
  <div class="modal-header">
    <div class="modal-header-titles">
      <span class="title">
        {{ "modal-search-partner.modal-detail.title" | translate }}
      </span>
    </div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefDetail.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body second modal-body--float">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="institutionId"> {{ "modal-search-partner.modal-detail.inputs.institution" | translate }} </label>
          <input type="text" class="form-control" value="{{partnerDetail?.institution}}" readonly />
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="agencyName"> {{ "modal-search-partner.modal-detail.inputs.agency" | translate }} </label>
          <input type="text" class="form-control" value="{{partnerDetail?.agencyName}}" readonly />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="name"> {{ "modal-search-partner.modal-detail.inputs.name" | translate }} </label>
          <input type="text" class="form-control" value="{{partnerDetail?.name}}" readonly />
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="birthDate"> {{ "modal-search-partner.modal-detail.inputs.birthDate" | translate }} </label>
          <input type="text" class="form-control" value="{{partnerDetail?.birthDate | date}}" readonly />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="documentTypeId"> {{ "modal-search-partner.modal-detail.inputs.documentType" | translate }} </label>
          <input type="text" class="form-control" value="{{partnerDetail?.documentTypeDescription}}" readonly />
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="documentNumber"> {{ "modal-search-partner.modal-detail.inputs.document" | translate }} </label>
          <input type="text" class="form-control" value="{{partnerDetail?.documentNumber}}" readonly />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="mobilePhoneCountryCode"> {{ "modal-search-partner.modal-detail.inputs.ddi" | translate }} </label>
          <input type="text" class="form-control" value="{{partnerDetail?.mobilePhoneCountryCode}}" readonly />
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="mobilePhone"> {{ "modal-search-partner.modal-detail.inputs.phone" | translate }} </label>
          <input type="text" class="form-control" value="{{partnerDetail?.mobilePhone}}" readonly />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="email"> {{ "modal-search-partner.modal-detail.inputs.email" | translate }} </label>
          <input type="text" class="form-control" value="{{partnerDetail?.email}}" readonly />
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="operatingModeDescription"> {{ "modal-search-partner.modal-detail.inputs.operationMode" | translate }} </label>
          <input type="text" class="form-control" value="{{partnerDetail?.operatingModeDescription}}" readonly />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="candidatureStatusDescription"> {{ "modal-search-partner.modal-detail.inputs.status" | translate }} </label>
          <input type="text" class="form-control" value="{{partnerDetail?.candidatureStatusDescription}}" readonly />
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="country"> {{ "modal-search-partner.modal-detail.inputs.country" | translate }} </label>
          <input type="text" class="form-control" value="{{partnerDetail?.address?.countryName}}" readonly />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="state"> {{ "modal-search-partner.modal-detail.inputs.state" | translate }} </label>
          <input type="text" class="form-control" value="{{partnerDetail?.address?.stateProvinceName}}" readonly />
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="form-group">
          <label for="city"> {{ "modal-search-partner.modal-detail.inputs.city" | translate }} </label>
          <input type="text" class="form-control" value="{{partnerDetail?.address?.cityName}}" readonly />
        </div>
      </div>
    </div>
  </div>
</ng-template>
