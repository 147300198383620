import { TokenService } from './../../../../core/auth/services/token.service';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TermsOfUse } from './interface/terms-of-use';
import { AgentService } from 'src/app/components/admin/partners/services/partner.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';
import { AgentModel } from 'src/app/components/admin/partners/models/partner.model';

@Component({
  selector: 'app-modal-end-term',
  templateUrl: './modal-end-term.component.html',
  styleUrls: ['./modal-end-term.component.css']
})
export class ModalEndTermComponent implements OnInit {

  checkedTerms: boolean = false;
  formConfiguration: any;
  accptedTermsTime: Dayjs;
  configurationDefault: any;
  policy: string;
  terms: [];
  contract: string;
  agent: AgentModel

  constructor(
    public bsModalRef: BsModalRef,
    private agentService: AgentService,
    private authService: AuthService,
    private router: Router,
    private tokenService: TokenService
  ) { }

  ngOnInit() {
   
  }

  onAcceptTerms() {
    this.agentService.setEndTerm().subscribe((result: any) => {
      this.bsModalRef.content.callback(true);
      this.bsModalRef.hide();
    });
  }


  propertyObject<T>(arrConfig: any): T {
    return arrConfig.reduce((obj, item) => (
      {
        ...obj,
        [item.name]: item.value
      }), {})
  }

  public decline(): void {
    this.bsModalRef.content.callback(null);
    this.bsModalRef.hide();
  }

  public accept(): void {
    this.bsModalRef.content.callback(true);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(null);
    this.bsModalRef.hide();
  }
}
