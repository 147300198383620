
    <div class="hotel mb-3 pb-0 row pt-0 ml-0 mr-0 flight" *ngIf="!!hotel">
        <div class="col-sm-5 pl-0 pr-0 h-23-mobile" [ngClass]="{'h35mobile': isMobile}">
            <a href="#0" class="wish_bt"></a>
            <div id="carouselExampleControls-{{hotel.id}}"
                class="carousel z-i9999 slide h-100p for-mobile-height-hotel-list" data-ride="carousel">
                <div class="carousel-inner h-100p">
                    <div class="carousel-item h-100p" *ngFor="let image of images; let i = index"
                        [ngClass]="{'active': i === 0}">
                        <div style="background-image: url({{image.publicUrl}});"
                            class="h-100p ajust-background-image-hotel">
                        </div>
                        <div class="carousel-caption pb-0" style="color: #ccc;">
                            <span class="icon-acoes ml-2" (click)="activateDeactivate(image)" *ngIf="!image.fileName">
                                <i class="fas fa-3x cursor-pointer" [class.fa-eye]="image.isActive"
                                    [class.fa-eye-slash]="!image.isActive"
                                    [tooltip]="defineTooltip(image.isActive)"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleControls-{{hotel.id}}" role="button"
                    data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only carrousel-hotel-list-previous-next "><span class="p-r-5"></span>
                    </span> </a> <a class="carousel-control-next" href="#carouselExampleControls-{{hotel.id}}"
                    role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only carrousel-hotel-list-previous-next "><span class="p-r-5"></span></span>
                </a>
            </div>
        </div>

        <div class="col-sm-7 pt-3 pb-2 text-left">
            <a href="#0" class="wish_bt"></a>

            <div class="d-flex flex-row">
                <h6 class="price mb-0 cursor-pointer mt-1">{{ hotel.name }}</h6>
                <div class="cat_star ml-auto">
                    <i class="fas fa-star" *ngFor="let star of numberArray(hotel.stars)"></i>
                </div>
            </div>

            <div class="d-flex flex-row">
                <label class="price mb-0 f1em" style="color: red;">
                    {{ (hotel.status === 'Close' || hotel.price?.price == 0 ? 'hotel-list.label.unavailable' : '') |
                    translate }}</label>
            </div>

            <div class="d-flex flex-row">
                <h6 class="price mb-0 mt-2 mr-3 f-18em">
                    $ 0.00</h6>
            </div>

            <label for="" class="label-info-hotel-list text-left mt-2 f-16"> No info </label>
            <br>
            <label for="" class="label-info-hotel-list text-left">
                <strong><i class="fas fa-map-marker-alt mr-1"></i></strong>
                &nbsp;{{ hotel.address.addressLine }}
            </label>

            <br>

            <label for="" class="label-info-hotel-list text-left mb-1 ml-1">{{'Taxes and fees included' |
                translate}}
            </label>

            <br>

            <label for="" class="price mb-0 ml-1 f1em">
                {{'Breakfast included' | translate}}
            </label>

            <br>

            <carousel [isAnimated]="true" [showIndicators]="false" [interval]="1500"
                class="carrousel-breakfast carrousel-no-buttons">
                <slide *ngFor="let item of hotel.amenities; let i = index">
                    • {{ item }}
                </slide>
            </carousel>

            <div class="row d-flex justify-content-center">
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 mt-2">
                    <button class="btn_1 btn-primary py-2 w-100 btn-green">Reserve this room</button>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 mt-2">
                    <button class="btn_1 btn-primary py-2 w-100">Reserve other room</button>
                </div>
            </div>
            <div class="divider oncard-hotel-divider"></div>

            <div class="d-flex flex-row">
                <span class="price mb-0 f1em">
                    <strong>
                        $ 0.00
                    </strong>
                    / night
                </span>
                <label for="" class="label-info-hotel-list ml-auto">
                    0 adults, 0 childs, 0 days
                </label>
            </div>

        </div>
    </div>
