import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[app-consulta-colunas]',
  templateUrl: './consulta-colunas.component.html',
  styleUrls: ['./consulta-colunas.component.sass']
})
export class ConsultaColunasComponent {

  @Input() cols: any[];

  @Input() actions: boolean;

  @Input() hasMetodo = true;

  @Output() metodo = new EventEmitter();

  order: boolean;

  lastColumn: string;

  constructor(public translate: TranslateService) { }

  public clickOrder(column: any): void {
    if (this.metodo) {
      const col = column.columnSort ? column.columnSort : column.name;
      this.changeNewVars(col);
      this.metodo.emit({columnSort: col, ascendingSort: this.order});
    }
  }

  private changeNewVars(col: string) {
    if (col !== this.lastColumn) {
      this.order = undefined;
    }
    this.order = !this.order;
    this.lastColumn = col;
  }
}
