import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/core/auth/services/token.service';
import { BaseService } from 'src/app/core/services/base.service';
import { LanguageModel } from '../models/language.model'

@Injectable({
  providedIn: 'root'
})
export class LanguageService extends BaseService<LanguageModel, string> {

  CurrentilyLanguageId: any;
  languages: any;
  constructor(protected injector: Injector, private tokenService: TokenService) {
    super(injector, '/api');
  }

  public getLanguage(): Observable<any> {
    return this.get(`/language`);
  }

  public getLanguagesActive(): Observable<any> {
    return this.get(`/language/active`);
  }

  public setLanguageId(id: number): void {
    this.CurrentilyLanguageId = id;
  }
  
  public getCurrentLanguage(): any {
    return JSON.parse(sessionStorage.getItem('currentLanguage'));
  }
}
