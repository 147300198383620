import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "src/app/core/services/base.service";
import { Institution } from '../models/institution.model';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService extends BaseService<Institution, number> {

  constructor(protected injector: Injector) {
      super(injector, '/api/institution');
  }

  public getHomeImages(): Observable<any> {
    return this.get('/active-home-images');
  }

  public searchHomeImages(page: number, totalPerPage?: number): Observable<any> {
    return this.get(`/search-home-images?page=${page}&totalPerPage=${totalPerPage}`);
  }

  public getCountries(): Observable<any> {
    return this.get('/countries');
  }

  public getPages(): Observable<any> {
    return this.get(`/pages`);
  }

  public getInstitution(): Observable<any> {
    return this.get('/all');
  }

  public getInstitutionById(id: number): Observable<any> {
    return this.get(`/${id}`);
  }
}
