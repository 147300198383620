import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'boldtext'
})
export class BoldtextPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}
    
    transform(value: any, ...params: any[]) {
        let fieldsToReplace = Object.keys(params[0]);
        fieldsToReplace.forEach(fieldToReplace => {
            value = value.replace(`{{${fieldToReplace}}}`, `<span class='${params[1]}'>${params[0][fieldToReplace]}</span>`);
        });
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}
