import { Directive, HostListener, HostBinding } from '@angular/core';

@Directive({
  selector: '[appTreeview]'
})
export class TreeviewDirective {
  @HostBinding('class.sidebar-collapse') isMenuOpen = false;

  @HostListener('click') toggleOpen(): void {
    // console.log(this.isMenuOpen);
    this.isMenuOpen = !this.isMenuOpen;
  }
}
