import { Component, Inject, Injector, OnInit } from '@angular/core';
import { BaseResourceFormComponent } from 'src/app/components/shared/components/base-resource-form/base-resource-form.component';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-confirm-modal-delete',
  templateUrl: './confirm-modal-delete.component.html',
  styleUrls: ['./confirm-modal-delete.component.scss'],
})
export class ConfirmModalDelete
  extends BaseResourceFormComponent
  implements OnInit
{
  currencieOptions: any = {
    prefix: '',
    thousands: '.',
    decimal: ',',
    align: 'left',
  };
  currenciesType: any;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ConfirmModalDelete>,
    @Inject(MAT_DIALOG_DATA)
    public data: boolean
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  protected createFrom(): void {
  }
}
