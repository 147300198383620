import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, AfterViewInit, OnInit, OnChanges, SimpleChanges } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-home-information-carousel-view',
  templateUrl: './home-information-carousel-view.component.html',
  styleUrls: ['./home-information-carousel-view.component.scss'],
})
export class HomeInformationCarouselViewComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() homeInfoCards: any[];
  @Input() displayType: number;
  @Input() qtd: number;
  @Input() styles: any;
  @Input() carouselStylesArray: any;

  dynamicId: any;
  mobileDevice = false;

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
    this.mobileDevice = false;

    this.breakpointObserver
      .observe(['(max-width: 992px)'])
      .subscribe((result: BreakpointState) => {
        this.mobileDevice = result.matches;
      });
  }

  ngOnInit(): void {
    this.dynamicId = Math.random().toString(36).substring(2, 11);
    console.log(this.styles);
    console.log(this.homeInfoCards);
    console.log(this.displayType);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.styles && !changes.styles.firstChange) {
      this.styles = changes.styles.currentValue;
      this.updateCarouselStyles();
    }
  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      const carouselClass = `.owl-carousel-${this.dynamicId}`;

      $(carouselClass).owlCarousel({
        loop: true,
        center: false,
        autoplay: true,
        nav: false,
        margin: 12,
        responsive: {
          0: {
            items: 1.2,
          },
          425: {
            items: 1.5
          },
          576: {
            items: 1.8
          },
          768: {
            items: 2.2
          },
          992: {
            items: 2
          },
          1020: {
            items: this.qtd,
            margin: 16
          },
          1440: {
            items: this.qtd,
            margin: 24
          }
        }
      });

      const nextButton = `.next-${this.dynamicId}`;
      const prevButton = `.prev-${this.dynamicId}`;

      $(nextButton).click(() => {
        $(carouselClass).trigger('next.owl.carousel');
      });

      $(prevButton).click(() => {
        $(carouselClass).trigger('prev.owl.carousel');
      });

    }, 500);

  }

  updateCarouselStyles() {
    const navigationClass = `.navigation-${this.displayType}`;
    const carouselClass = `.owl-carousel-${this.dynamicId} .item-${this.displayType}`;
    console.log()
    for (const styleKey in this.styles) {
      if (this.styles.hasOwnProperty(styleKey)) {
        const styleValue = this.styles[styleKey];
        console.log(styleKey, styleValue);
        switch (styleKey) {
          case 'qtd':
            this.qtd = +styleValue;
            break;
          case 'top':
            $(navigationClass).css('top', styleValue);
            break;
          case 'height':
            $(carouselClass).css('height', styleValue);
            break;
          case 'widthText':
            $(carouselClass + ' .text-information').css('width', styleValue);
            break;
          case 'heightText':
            $(carouselClass + ' .text-information').css('height', styleValue);
            break;
          case 'flexDirection':
            $(carouselClass).css('flex-direction', styleValue);
            break;
          case 'titleFontSize':
            $(carouselClass + ' .text-information .title').css('font-size', styleValue);
            break;
          case 'subtitleFontSize':
            $(carouselClass + ' .text-information .subtitle').css('font-size', styleValue);
            break;
          case 'buttonFontSize':
            $(carouselClass + ' .text-information button').css('font-size', styleValue);
            break;
          case 'widthImage':
            $(carouselClass + ' .image').css('width', styleValue);
            break;
          case 'heightImage':
            $(carouselClass + ' .image').css('height', styleValue);
            break;
          case 'borderRadiusImageTopLeft':
            $(carouselClass + ' .image img').css('border-top-left-radius', styleValue);
            break;
          case 'borderRadiusImageTopRight':
            $(carouselClass + ' .image img').css('border-top-right-radius', styleValue);
            break;
          case 'borderRadiusImageBottomRight':
            $(carouselClass + ' .image img').css('border-bottom-right-radius', styleValue);
            break;
          case 'borderRadiusImageBottomLeft':
            $(carouselClass + ' .image img').css('border-bottom-left-radius', styleValue);
            break;
          default:
            break;
        }
      }
    }

    $(carouselClass).trigger('destroy.owl.carousel');
    this.ngAfterViewInit();
  }


}
