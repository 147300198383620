import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { HotelModel } from 'src/app/components/admin/hotel/models/hotel.model';
import { ImageModel } from 'src/app/components/admin/hotel/models/image.model';
import { BaseResourceFormComponent } from '../base-resource-form/base-resource-form.component';


declare var $: any;
@Component({
  selector: 'app-hotel-card-view',
  templateUrl: './hotel-card-view.component.html',
  styleUrls: ['./hotel-card-view.component.css'],
  providers: [NgbCarouselConfig],
})
export class HotelCardViewComponent extends BaseResourceFormComponent implements OnChanges, AfterViewInit {

  @Input() hotelData: HotelModel;
  @Input() images: ImageModel[];
  @Output() activateDeactivateImage = new EventEmitter();

  hotel: HotelModel;
  order = true;

  carouselDesktop = [];
  fixedDesktop = [];
  constructor(injector: Injector, config: NgbCarouselConfig) {
    super(injector);
    config.keyboard = false;
    config.pauseOnHover = true;
  }

  protected createFrom(): void {
  }

  numberArray(value: number): number[] {
    return new Array(value);
  }

  ngOnChanges(): void {
    if (this.hotelData) {
      this.hotel = this.hotelData;
      this.loadImages();
    }
  }

  loadImages(): void {
    this.fixedDesktop.push(this.images[0]);
    this.images.forEach(roomImage => {
      this.fixedDesktop.push(roomImage);
    });
    if (this.images.length < 4) {
      this.carouselDesktop.slice(0, 4).forEach(element => {
        this.fixedDesktop.push(element);
      });
    }
    this.fixedDesktop = this.fixedDesktop.reverse();
  }

  activateDeactivate(imageModel: ImageModel) {
    this.activateDeactivateImage.emit(this.images.indexOf(imageModel) + '');
  }

  public defineTooltip(isActive: boolean): string {
    const prefix = isActive ? 'de' : '';
    return this.translate.instant(`image-viewer.tooltip.${prefix}activate`);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // tslint:disable-next-line: space-before-function-paren
      $(`#owl-carousel-${this.hotel.hotelId}`).each(function (): void {
        $(this).owlCarousel({
          loop: true,
          margin: 10,
          center: true,
          autoplay: true,
          nav: false,
          responsive: {
            0: {
              items: 1,
            },
            767: {
              items: 2,
            },
            1000: {
              items: 4,
              margin: 20,
            },
            1400: {
              items: 5,
              margin: 20,
            },
          },
        });
      });

      $(`#next-${this.hotel.hotelId}`).click(() => {
        $(`#owl-carousel-${this.hotel.hotelId}`).trigger('next.owl.carousel');
      });
      $(`#prev-${this.hotel.hotelId}`).click(() => {
        $(`#owl-carousel-${this.hotel.hotelId}`).trigger('prev.owl.carousel');
      });
    }, 1000);
  }
}
