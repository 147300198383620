<aside class="main-sidebar sidebar-dark-primary elevation-4">
  <a class="brand-link">
    <img [src]="userPhotoProfile" alt="onertravel" class="brand-image img-circle elevation-3" style="opacity: .8"
      *ngIf="userPhotoProfile">
    <span class="brand-text font-weight-light">{{ userLogged | slice:0:25 }}</span>
  </a>

  <div class="sidebar">
    <div class="content-menu">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <ng-container *ngTemplateOutlet="recursiveMenu; context:{ list: pages }">
        </ng-container>
      </ul>
    </div>
  </div>
</aside>

<aside class="control-sidebar control-sidebar-dark">
  <!-- Control sidebar content goes here -->
</aside>

<ng-template #recursiveMenu let-list="list">
  <li class="nav-item" *ngFor="let item of list; let i = index">
    <a class="nav-link cursor-pointer" (click)="navegar(item.url)" routerLinkActive="router-link-active"
      [attr.data-widget]="item.childrens?.length > 0 ? '' :'pushmenu'">
      <i class="{{item?.icon}}"></i>
      <p>
        {{item.title | translate}}
        <i class="right fas fa-angle-left" *ngIf="item.childrens?.length > 0"></i>
      </p>
    </a>
    <ul class="nav nav-treeview" *ngIf="item.childrens?.length > 0" style="margin-left: 0.5rem;">
      <ng-container *ngTemplateOutlet="recursiveMenu; context:{  list: item.childrens }"></ng-container>
    </ul>
  </li>
</ng-template>
