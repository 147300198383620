import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeurl'
})
export class ViewImagePipe implements PipeTransform {
  // const urlBaseArchive = environment.backendUrl;

  constructor(private domSanitizer: DomSanitizer) { }

  transform(hash: string) {
    // return this.domSanitizer.bypassSecurityTrustUrl(this.urlBaseArchive + '/api/archive/' + hash);
    return hash;
  }

}
