import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { TokenService } from 'src/app/core/auth/services/token.service';
import { TranslateService } from '@ngx-translate/core';
import { InstitutionService } from 'src/app/components/shared/services/institution.service';
import { BaseResourceFormComponent } from '../../../base-resource-form/base-resource-form.component';
import { version } from './../../../../../../../version'
import { ImageCropperModalComponent } from '../../../image-cropper/image-cropper-modal.component';
import { formatDate } from '@angular/common';
import Messages from 'src/app/components/shared/enums/messages.enum';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent extends BaseResourceFormComponent implements OnInit {
  isDarkTheme = false;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 768px)'])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  protected createFrom(): void {

  }

  userLogged: string;
  userPhotoProfile: string;
  roleSession: string;
  logoIntitution: string;
  cardImageBase64: string;
  croppedImage: any;
  imgUrl: any;
  uploadImg = false;
  existsPhoto = false;
  modalRef: BsModalRef;
  photoName: string;
  userId: string;
  version = version;

  @ViewChild('photoFile') photoFile: ElementRef;


  pages = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    public router: Router,
    injector: Injector,
    private modalService: BsModalService,
    private authService: AuthService,
    public tokenService: TokenService,
    private translateService: TranslateService,
    private institutionService: InstitutionService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.userLogged = this.tokenService.getToken('userName');
    this.roleSession = this.tokenService.getToken(this.tokenService.TOKEN_ROLE);
    this.userPhotoProfile = this.tokenService.getToken(this.tokenService.TOKEN_IMAGE_PROFILE);
    this.logoIntitution = this.tokenService.getToken(this.tokenService.TOKEN_INTITUTION_LOGO);
    this.userId = this.tokenService.getToken(this.tokenService.TOKEN_USER_ID);
    this.pages = JSON.parse(sessionStorage.getItem('pages'));
    // $('[data-widget="treeview"]').Treeview('init');
    console.log(this.pages);

    if (!this.pages || this.pages.length === 0) {
      this.institutionService.getPages().subscribe((pages: any) => {
        sessionStorage.setItem('pages', JSON.stringify(pages.data));
        this.pages = pages.data;
      });
    }
  }

  navegar(link: string): void {
    this.router.navigate([link]);
  }

  navegarClient(): void {
    this.router.navigate([`admin/clients/${this.tokenService.getToken(this.tokenService.TOKEN_PERSON_ID)}`]);
  }

  visibleComponent(roles: string[]): boolean {
    return roles.includes(this.roleSession);
  }


  public clickPhotoFile(): void {
    this.photoFile.nativeElement.click();
  }

  public fileChangeEvent(event: any) {
    this.getBase64(event.target.files[0]);
  }

  private getBase64(file: any): void {
    this.photoName = file.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.cardImageBase64 = reader.result.toString();
      this.openDialog();
    };
    reader.onerror = (error) => {
      console.error('Error: ', error);
    };
  }

  private openDialog() {
    this.modalRef = this.modalService.show(ImageCropperModalComponent,
      {
        initialState: {
          imageBase64: this.cardImageBase64,
          callback: (result: boolean) => {
            this.croppedImage = result;
            this.save();
            this.uploadImg = true;
            this.existsPhoto = false;
            this.modalRef.hide();
          }
        },
        ignoreBackdropClick: true
      });
  }

  private save() {

    if (this.croppedImage) {

      const profileBase64 = { profilePictureBase64: this.croppedImage.replace(/^data:image\/[a-z]+;base64,/, "") };
      this.authService.addImageProfile(this.userId, profileBase64).subscribe((result) => {
        if (result.success) {
          this.userPhotoProfile = result.data;
          this.notificationService.messageSuccess(this.translate.instant(Messages.SUCCESS_SAVE));
        }
      });
    } else {
      this.modalService.hide;
    }
  }

  versionClick(version: any) {
    let versionText = `Versão: v${version.textVersion}`
    versionText += `\r\nData: ${version.versionDate ? formatDate(version.versionDate, 'MM/dd/yyyy', 'pt') : ""}`
    alert(versionText);
  }

}
