import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ImageCropperModalComponent } from '../image-cropper/image-cropper-modal.component';

@Component({
  selector: 'app-offer-template-thumb-upload',
  templateUrl: './offer-template-thumb-upload.component.html',
  styleUrls: ['./offer-template-thumb-upload.component.scss']
})
export class OfferTemplateThumbUploadComponent implements OnInit {
  @Input() imageUrl: string;
  @ViewChild('imageFile') imageFile: ElementRef;
  croppedImage = null;
  imageName = null;
  imageBase64 = null;
  modalRef: BsModalRef;
  @Output() uploaded: EventEmitter<any> = new EventEmitter<any>();
  
  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
  }

  clickImageFile(): void {
    this.imageFile.nativeElement.click();
  }

  fileChangeEvent(event: any) {
    this.getBase64(event.target.files[0]);
  }

  private getBase64(file: any): void {
    this.imageName = file.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imageBase64 = reader.result.toString();
      this.openDialog();
    };
    reader.onerror = (error) => {
      console.error('Error: ', error);
    };
  }

  private openDialog() {
    this.modalRef = this.modalService.show(ImageCropperModalComponent,
      {
        initialState: {
          imageBase64: this.imageBase64,
          callback: (result: boolean) => {
            this.croppedImage = result;
            this.modalRef.hide();
            this.uploaded.emit({imageName: this.imageName, imageBase64: this.imageBase64});
          }
        },
        ignoreBackdropClick: true
      });
  }

}
