import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService extends BaseService<any, string> {

  CurrentilyLanguageId: any;
  languages: any;
  constructor(protected injector: Injector) {
    super(injector, '/api');
  }

  public getLanguagesActive(): Observable<any> {
    return this.get(`/language/active`);
  }

  public setLanguageId(id: number): void {
    this.CurrentilyLanguageId = id;
  }

  public getConfigurations(){
    return this.get('/configurations/search');
  }
}
