import { Injectable } from '@angular/core';
import { Currencie } from './model/currencie.model';

@Injectable({
  providedIn: 'root'
})
export class CurrencieService {

  currencies: Currencie[];

  constructor() {
  }

  public loadCurrencies(): Currencie[] {
    this.currencies = [
      {
        id: 1,
        currencie: 'R$',
        country: 'BRL',
        defaultLanguage: 'pt'
      },
      {
        id: 2,
        currencie: '$',
        country: 'USD',
        defaultLanguage: 'en-us'
      },
      {
        id: 3,
        currencie: '$',
        country: 'USD',
        defaultLanguage: 'en-gb'
      },
      {
        id: 4,
        currencie: '€',
        country: 'EUR',
        defaultLanguage: 'eu'
      },
      {
        id: 5,
        currencie: '$',
        country: 'MXN',
        defaultLanguage: 'es-mx'
      },
      {
        id: 6,
        currencie: 'R$',
        country: 'BRL',
        defaultLanguage: 'pt-br'
      },
    ];
    return this.currencies;
  }

  setCurrencieByDefaultLanguage(defaultLanguage: string): void {
    this.currencies = this.loadCurrencies();
    const deafultCurrencie = this.currencies.find(element => element.defaultLanguage === defaultLanguage);
    sessionStorage.removeItem('currencie');
    sessionStorage.setItem('currencie', JSON.stringify(deafultCurrencie));
  }
}
