import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  readonly EMAIL = 'email';
  readonly LOGIN_USER_TOKEN = 'userToken';
  readonly LOGIN_IS_FIRST_ACCESS = 'isFirstAccess';
  readonly CHANGE_PASSWORD = 'changePassword';

  readonly TOKEN_USER_ID = 'userId';
  readonly TOKEN_PERSON_ID = 'personId';
  readonly TOKEN_FULL_NAME = 'userName';
  readonly TOKEN_IMAGE_PROFILE = 'profilePictureUrl';
  readonly TOKEN_ROLE = 'role';
  readonly TOKEN_INTITUTION_ID = 'InstitutionId';
  readonly TOKEN_INTITUTION_LOGO = 'InstitutionLogo';

  readonly AGENT = 'agent';
  readonly REMEMBER_USER = 'rememberUser';

  readonly COUPON_MENU = 'cuponMenu';
  readonly PARTNER_SITE= 'partnerSite'

  public jwtHelperService = new JwtHelperService();

  constructor() { }

  public createToken(keyToken: string, valorToken: string): void {
    localStorage.setItem(keyToken, valorToken);
  }

  public getToken(keyToken: string): string {
    return localStorage.getItem(keyToken);
  }

  public updateToken(keyRefreshToken: string): string {
    return localStorage.getItem(keyRefreshToken);
  }

  public getDateValidateToken(token?: string): Date | null {
    return this.jwtHelperService.getTokenExpirationDate(token);
  }

  public verifyTokenExpired(token: string): boolean {
    return this.jwtHelperService.isTokenExpired(token);
  }

  public decodeToken(token: string): any {
    return this.jwtHelperService.decodeToken(token);
  }

  public verifyTokenActive(keyToken: string): boolean {
    return !!localStorage.getItem(keyToken);
  }

  public removeToken(): void {
    localStorage.clear();
  }

}

