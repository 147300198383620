import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header-login',
  templateUrl: './header-login.component.html',
  styleUrls: ['./header-login.component.sass']
})
export class HeaderLoginComponent implements OnInit {

  idioma: string;

  currentFlag: any;
  defualtLanguage = 'en-us';
  languages: any;
  constructor(private translateService: TranslateService,  @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.idioma = this.translateService.getDefaultLang();
    this.configureLanguage();
    this.translateService.onDefaultLangChange.subscribe((event: LangChangeEvent) => {
      this.idioma = event.lang;
      this.configureLanguage();
    });
  }

  useLanguage(lang): void {
    this.translateService.setDefaultLang(lang.codeIso);
    localStorage.setItem('lang',lang.codeIso);
    sessionStorage.setItem('currentLanguage', JSON.stringify(lang));
    this.idioma = lang.codeIso;
    this.currentFlag = lang;
    this.document.documentElement.lang = lang.codeIso;
  }

  public configureLanguage() {
    this.idioma = this.translateService.getDefaultLang() ?? this.defualtLanguage;
    var storage = sessionStorage.getItem('languages');
    if (storage) {
      this.languages = JSON.parse(storage);
      this.languages?.map(item => item.icon = `assets/img/${item.icon}`);
      this.currentFlag = this.languages?.find(item => item.codeIso == this.idioma);
    }
  }
}
