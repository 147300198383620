import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { LoginComponent } from './pages/login.component';
import { AuthComponent } from './auth.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { AuthenticationComponent } from './pages/authentication/authentication.component';
import { AppLanguageResolver } from 'src/app/resolver/app.language.resolver';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: '', component: AuthenticationComponent, resolve: { language: AppLanguageResolver } },
      { path: 'reset-password', component: ResetPasswordComponent, resolve: { language: AppLanguageResolver } },
      { path: 'authenticated/:jwt', component: LoginComponent },
      { path: 'authenticated/:jwt/:bookingId', component: RedirectComponent },
      { path: 'authenticated/redirect/:jwt/:component', component: RedirectComponent },
      { path: 'authentication', component: AuthenticationComponent, resolve: { language: AppLanguageResolver } }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
