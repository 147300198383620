import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLanguageResolver } from './resolver/app.language.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'clearcache',
    loadChildren: () => import('./components/clear-cache/clear-cache.module').then(m => m.ClearCacheModule),
    resolve: { language: AppLanguageResolver }
  },
  {
    path: 'login',
    loadChildren: () => import('./components/login/auth.module').then(m => m.AuthModule),
    resolve: { language: AppLanguageResolver }
  },
  {
    path: 'admin',
    loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule),
    resolve: { language: AppLanguageResolver }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
