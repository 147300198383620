import { AgentService } from 'src/app/components/admin/partners/services/partner.service';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { CurrencieService } from 'src/app/core/services/currencie.service';
import { DOCUMENT } from '@angular/common';
import { BaseResourceFormComponent } from 'src/app/components/shared/components/base-resource-form/base-resource-form.component';
import { InstitutionService } from 'src/app/components/shared/services/institution.service';
import { LanguageService } from 'src/app/components/admin/points-interest/services/language.service';
import { ModalTermsConditionsComponent } from 'src/app/components/shared/components/modal-terms-conditions/modal-terms-conditions.component';
import Messages from 'src/app/components/shared/enums/messages.enum';
import * as dayjs from 'dayjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { Configuration } from 'src/app/components/shared/models/configuration';
import { ModalEndTermComponent } from 'src/app/components/shared/components/modal-end-term/modal-end-term.component';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent extends BaseResourceFormComponent implements OnInit {

  formSubmitted = false;
  modalRef: BsModalRef;
  languages: any;
  hash: string;
  anonymousEmail: string;
  anonymousPhone: string;
  email: string;
  errorCode: string;
  errorEmail: string;
  isSentEmail: boolean;
  hasHashValid: boolean = false;
  codePlaceholder: string;
  mobilePhone: string;
  mobilePhoneCountryCode: number;

  isByPassword: boolean;
  formByPassword: UntypedFormGroup;
  hidePassword: boolean = false;
  errorLogin: string;

  constructor(injector: Injector,
    private authService: AuthService,
    private institutionService: InstitutionService,
    private modalService: BsModalService,
    private currencieService: CurrencieService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private agentService: AgentService,
    private loaderService: NgxSpinnerService,
    private configurationService: ConfigurationService,
    @Inject(DOCUMENT) private document: Document) {
    super(injector);
  }

  ngOnInit(): void {
    const hasUserLocaStorage = this.authService.check();
    if (!hasUserLocaStorage) {
      if (this.tokenService.getToken(this.tokenService.REMEMBER_USER) === 'true') {
        this.authService.createUserSession(this.tokenService.getToken('userToken'));
      }
    }

    this.route.params.subscribe((res) => {
      if (res.jwt) {
        this.authService.createUserSession(res.jwt);
      }
    });

    this.codePlaceholder = 'authentication.input-code-placeholder-email';

    this.route.queryParams.subscribe(params => {
      this.hash = params['creationHash'];

      if (this.hash) {
        this.agentService.getUserByHash(this.hash).subscribe((user: any) => {
          this.hasHashValid = true;
          this.isSentEmail = true;
          this.anonymousEmail = user.data.anonymousEmail;
          this.anonymousPhone = user.data.anonymousPhone;
          this.email = user.data.email;
          this.form.controls.email.setValue(this.email);
          this.form.controls.email.updateValueAndValidity();
          this.mobilePhone = user.data.mobilePhone;
          this.mobilePhoneCountryCode = user.data.mobilePhoneCountryCode;
          this.codePlaceholder = 'authentication.input-code-placeholder';
        }, (error: any) => { });
      }
    }, (error: any) => { });

    this.route.data.subscribe((resolve: any) => {
      this.languages = resolve?.language?.data;
      if (this.languages) {
        sessionStorage.setItem('languages', JSON.stringify(this.languages));
      }
    });

    const browserlang = navigator.language.toLowerCase();
    const langStorage = localStorage.getItem('lang') ?? browserlang;
    if (browserlang) {
      var currentLanguage = this.languages?.find(item => item.codeIso === langStorage);
      if (currentLanguage) {
        sessionStorage.setItem('currentLanguage', JSON.stringify(currentLanguage));
        this.translateService.setDefaultLang(langStorage);
        this.currencieService.setCurrencieByDefaultLanguage(langStorage);
        this.document.documentElement.lang = langStorage;
      } else {
        this.defaultLanguageIfNull();
      }
    } else {
      this.defaultLanguageIfNull();
    }
  }

  private defaultLanguageIfNull() {
    let en = 'en-us';
    this.document.documentElement.lang = en;
    this.translateService.setDefaultLang(en);
    this.currencieService.setCurrencieByDefaultLanguage(en);
    this.languageService.setLanguageId(this.languages?.find(item => item.codeIso === en).id);
  }

  createFrom(): void {
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email, Validators.pattern(new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))]],
      authenticationCode: [null, Validators.required],
      remember: [false]
    });

    this.formByPassword = this.formBuilder.group({
      login: [null, [Validators.required, Validators.email, Validators.pattern(new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))]],
      password: [null, Validators.required],
      remember: [false]
    });
  }

  check(e) {
    const code = e.target.value;
    if (this.formSubmitted) {
      this.errorCode = null;
      this.errorEmail = null;
    }
  }

  sendCode(resend?: boolean): void {
    this.formSubmitted = true;
    this.errorEmail = null;

    if (!this.form.controls.email.valid) {
      this.form.controls.email.markAsTouched();
      return;
    }

    this.formSubmitted = false;
    const email = this.form.value.email;

    if (!!email) {
      const authCode: any = {
        email,
        mobilePhoneCountryCode: this.mobilePhoneCountryCode ?? null,
        mobilePhone: this.mobilePhone ?? null
      };

      this.loaderService.show();
      this.authService.sendBackofficeCode(authCode).subscribe(
        () => {
          this.isSentEmail = true;
          this.formSubmitted = false;
          this.loaderService.hide();
          if (resend) {
            this.notificationService.messageResendCode(this.translateService.instant('authentication.resend-code'));
          }
        }, () => {
          this.errorEmail = this.translate.instant(Messages.INVALID_EMAIL);
          this.formSubmitted = true;
          this.loaderService.hide();
          this.form.controls.email.markAsTouched();
        });
    }
  }

  validate(): void {
    this.formSubmitted = true;
    this.errorCode = null;

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.formSubmitted = false;
    const dateTimezone = dayjs(new Date());
    const usuario = {
      email: this.email ? this.email : this.form.controls.email.value,
      authenticationCode: this.form.controls.authenticationCode.value,
      agentId: null,
      dateTimeClient: dateTimezone.format()
    }

    this.loaderService.show();
    this.authService.authenticationCode(usuario).subscribe((result: any) => {
      this.loaderService.hide();

      this.authService.createUserSession(result.data.token, `${this.form.controls.remember.value}`);
      this.getPages();
      this.getConfigurations();
      this.checkTermsAndConditions(result.data.token);
    }, () => {
      this.loaderService.hide();

      this.errorCode = this.translate.instant(Messages.INVALID_CODE);
      this.formSubmitted = true;
      this.form.controls.authenticationCode.markAsTouched();
    });
  }

  loginByPassword(): void {
    this.formSubmitted = true;

    if (!this.formByPassword.valid) {
      this.formByPassword.markAllAsTouched();
      return;
    }

    const usuario = this.formByPassword.value;
    const dateTimezone = dayjs(new Date());
    usuario.dateTimeClient = dateTimezone.format();
    delete usuario.remember;

    this.authService.login(usuario).subscribe((result: any) => {
      this.authService.createUserSession(result.data.token, `${this.formByPassword.controls.remember.value}`);
      this.getPages();
      this.getConfigurations();
      this.checkTermsAndConditions(result.data.token);
      // this.checkEndTerm();
    }, (error: any) => {
      this.errorLogin = this.translate.instant(Messages.LOGIN_ERROR_USER_PASSWORD);
      this.formSubmitted = true;
      this.formByPassword.markAllAsTouched();
    });
  }

  getPages(): void {
    this.institutionService.getPages().subscribe((pages: any) => {
      sessionStorage.setItem('pages', JSON.stringify(pages.data));
    });
  }

  checkTermsAndConditions(authToken: any): void {
    const tokenDecoded: any = this.tokenService.decodeToken(authToken);
    if (tokenDecoded.role === 'AGENT' && tokenDecoded.AcceptedTermsAndContract === 'False') {
      this.openModalTermsConditions();
    }
  }

  checkEndTerm(): void {
    this.agentService.getEndTerm().subscribe((result: any) => {
      console.log(result);
      if (result.data === false) {
        this.openEndTermModal();
      }
    });
  }

  openModalTermsConditions(): void {
    this.modalRef = this.modalService.show(ModalTermsConditionsComponent,
      {
        backdrop: true,
        animated: true,
        ignoreBackdropClick: true,
        keyboard: false,
        initialState: {
          callback: (result: boolean) => {
            if (result) { }
          }
        }
      });
  }

  openEndTermModal(): void {
    this.modalRef = this.modalService.show(ModalEndTermComponent,
      {
        backdrop: true,
        animated: true,
        ignoreBackdropClick: true,
        keyboard: false,
        initialState: {
          callback: (result: boolean) => {
            if (result) {
              // console.log(result);
            }
          }
        }
      });
  }
  
  onByPassword(isByPassword?: boolean): void {
    this.isByPassword = !this.isByPassword;
    this.errorCode = null;
    this.errorEmail = null;
    this.formSubmitted = false;
    this.isSentEmail = false;
    this.errorLogin = null;
    if (this.form.controls.email.value && !isByPassword) {
      this.formByPassword.get('login').setValue(this.form.controls.email.value);
      this.formByPassword.get('password').reset();
      this.formByPassword.updateValueAndValidity();
    }
    if (this.formByPassword.controls.login.value && isByPassword) {
      this.form.get('email').setValue(this.formByPassword.controls.login.value);
      this.form.get('authenticationCode').reset();
      this.form.updateValueAndValidity();
    }
  }

  onHidePassword(): void {
    this.hidePassword = !this.hidePassword;
  }

  resetPassword(): void {
    this.router.navigate(['/login/reset-password']);
  }

  getConfigurations() {
    this.configurationService.getConfigurations()
      .subscribe(result => {
        const couponMenu = result.data.configurationsList.find((config: Configuration) => config.name === 'CouponEnabled');
        if (couponMenu) {
          this.tokenService.createToken(this.tokenService.COUPON_MENU, couponMenu.value);
        }
      });
  }

}
