import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/components/admin/points-interest/services/language.service';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { AgentService } from 'src/app/components/admin/partners/services/partner.service';
import { AgentModel } from 'src/app/components/admin/partners/models/partner.model';
import { TokenService } from 'src/app/core/auth/services/token.service';
import { SiteModel } from 'src/app/components/admin/site/models/site.model';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  idioma: string;
  languages: any;
  currentFlag = { displayName: '', icon: '' };
  defualtLanguage = 'en-us';
  agencySite: any;
  isHidden: boolean;
  agencyInfo: SiteModel;
  isMobile = false;

  constructor(private authService: AuthService,
    private router: Router,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private agentService: AgentService,
    private tokenService: TokenService,
    private deviceDetector: DeviceDetectorService,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.isMobile = (this.deviceDetector.isMobile() || this.deviceDetector.isTablet());
    this.isHidden = true;
    this.idioma = this.translateService.getDefaultLang();
    this.configureLanguage();
    this.translateService.onDefaultLangChange.subscribe((event: LangChangeEvent) => {
      this.idioma = event.lang;
      this.configureLanguage();
    });
    this.configureSite();

  }
  configureSite() {
    const agent: AgentModel = JSON.parse(this.tokenService.getToken(this.tokenService.AGENT));
    if (agent != null) {
      this.agentService.getAgency(agent.id).subscribe((result) => {
        if (result.success) {
          this.agencyInfo = Object.assign({}, result.data);
          this.agencySite = this.agencyInfo?.agencySite;
          this.isHidden = false;
          this.tokenService.createToken(this.tokenService.PARTNER_SITE, this.agencySite);
        }
      });
    }
  }

  sair() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  useLanguage(lang: any): void {
    this.languageService.setLanguageId(lang.id);
    this.translateService.setDefaultLang(lang.codeIso);
    localStorage.setItem('lang', lang.codeIso);
    this.idioma = lang.codeIso;
    this.currentFlag = lang
    this.document.documentElement.lang = lang.codeIso;
  }

  public configureLanguage() {
    var languageStorage = sessionStorage.getItem('languages');
    if (languageStorage) {
      this.languages = JSON.parse(languageStorage);
      this.languages?.map(item => item.icon = `assets/img/${item.icon}`);
      this.currentFlag = this.languages?.find(item => item.codeIso == this.idioma ?? this.defualtLanguage);
    }
  }
}
