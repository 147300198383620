import { Injector } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TokenService } from 'src/app/core/auth/services/token.service';
import { Currencie } from 'src/app/core/services/model/currencie.model';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Sort } from './model/sort.model';

export abstract class BaseResourceFormComponent {

    public form: UntypedFormGroup;
    public formSubmitted = false;
    public currencie: Currencie;
    public sort: Sort;
    public firstAccess = false;
    public isMobile: boolean;
    protected route: ActivatedRoute;
    protected router: Router;
    protected formBuilder: UntypedFormBuilder;
    protected translate: TranslateService;
    protected tokenService: TokenService;
    protected notificationService: NotificationService;
    protected deviceDetectorService: DeviceDetectorService;

    constructor(protected injector: Injector) {
      this.route = this.injector.get(ActivatedRoute);
      this.router = this.injector.get(Router);
      this.formBuilder = this.injector.get(UntypedFormBuilder);
      this.translate = this.injector.get(TranslateService);
      this.tokenService = this.injector.get(TokenService);
      this.notificationService = this.injector.get(NotificationService);
      this.deviceDetectorService = this.injector.get(DeviceDetectorService);
      this.isMobile = (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet());
      this.firstAccess = this.tokenService.getToken(this.tokenService.LOGIN_IS_FIRST_ACCESS) === 'S';
      this.currencie = JSON.parse(sessionStorage.getItem('currencie'));
      this.createFrom();
    }

    protected abstract createFrom(): void;
  }
