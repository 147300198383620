import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { version } from './../../../../../../version';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {

  version = version;

  constructor(
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
  }

  versionClick(version: any) {
    let versionText = `Versão: v${version.textVersion} ${version.versionDate ? ' - ' : ''}`
    versionText += `Data: ${version.versionDate ? formatDate(version.versionDate, 'MM/dd/yyyy', 'pt') : ""}`
    this._snackBar.open(versionText, 'OK', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: "snack-dialog",
      duration: 5000
    });
  }

}
