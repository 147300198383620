import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper-modal.component.html',
})
export class ImageCropperModalComponent implements OnInit {

  croppedImage: any = '';
  @Input() imageBase64: any;

  scale = 1;
  transform: ImageTransform = {};

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  public decline(): void {
    this.bsModalRef.content.callback(null);
    this.bsModalRef.hide();
  }

  public accept(): void {
    this.bsModalRef.content.callback(this.croppedImage);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(null);
    this.bsModalRef.hide();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  loadImageFailed() {
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
  }
}
