<div id="sign-in-dialog" class="zoom-anim-dialog" style="padding: 1.5rem;">
    <div class="sign-in-wrapper mt-sm-4 mt-5">
      <div class="d-flex flex-row justify-content-center mt-3">
        <img src="assets/img/terms.svg" width="150px" alt="">
      </div>
  
      <div class="d-flex flex-row justify-content-center mt-3">
        <h4 class="app-f18 text-center">
          {{'modal-terms-comissions.title-text' | translate}}
          <a class="font-weight-bold" style="border: none;font-size: 20px;" *ngFor="let item of terms; last as isLast" href="{{item.url}}" target="_blank">
            {{ getTermNameToTranslate(item.name) | translate }}
          </a>
        </h4>
      </div>
    </div>
</div>