import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'data'
})
export class DataPipe implements PipeTransform {

  constructor(
    private datePipe: DatePipe
  ) { }

  transform(date: any | string, format: string): any {
    return this.datePipe.transform(date, format);
  }
}
