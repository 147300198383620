<div class="caousel-navigation navigation" [ngClass]="'navigation-' + displayType" [hidden]="mobileDevice">
  <a class="prev" [ngClass]="'prev-' + dynamicId">
    <mat-icon>chevron_left</mat-icon>
  </a>
  <a class="next" [ngClass]="'next-' + dynamicId">
    <mat-icon>chevron_right</mat-icon>
  </a>
</div>


<div class="owl-carousel owl-carousel-{{dynamicId}}">
  <mat-card class="item flex-direction" *ngFor="let card of homeInfoCards" [ngClass]="'item-' + displayType">
    <div class="text-information">
      <div class="text">
        <div class="title">{{ card.title }}</div>
        <div class="subtitle">{{ card.subtitle }}</div>
      </div>
      <div class="button" *ngIf="card.titleButton">
        <button mat-flat-button color="primary">{{card.titleButton}}</button>
      </div>
    </div>
    <div class="image">
      <img [src]="card.urlImage" alt="{{ card.title }}">
    </div>
  </mat-card>
</div>