import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AgentModel } from 'src/app/components/admin/partners/models/partner.model';
import { AgentService } from 'src/app/components/admin/partners/services/partner.service';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { TokenService } from 'src/app/core/auth/services/token.service';
import { TermsOfUse } from '../../interface/terms-of-use';

@Component({
  selector: 'app-modal-terms-conditions-comission',
  templateUrl: './modal-terms-conditions-comission.component.html',
  styleUrls: ['./modal-terms-conditions-comission.component.sass']
})
export class ModalTermsConditionsComissionComponent implements OnInit {
  checkedTerms: boolean = false;
  formConfiguration: any;
  accptedTermsTime: Dayjs;
  configurationDefault: any;
  policy: string;
  terms: [];
  contract: string;
  agent: AgentModel

  constructor(
    public bsModalRef: BsModalRef,
    private agentService: AgentService,
    private authService: AuthService,
    private router: Router,
    private tokenService: TokenService) { }

  ngOnInit() {
    const userId = JSON.parse(this.tokenService.getToken(this.tokenService.TOKEN_PERSON_ID));
    this.agentService.getAgentByPerson(userId).subscribe((agent: any) => {
      this.configurationDefault = this.propertyObject(agent.data.configurations);
      if (agent.data.operatingMode === 1) {
        this.terms = agent.data.termsToAccept.filter((element: any) => {
          return element.name !== 'PartnerContractPJ';
        });
      } else {
        this.terms = agent.data.termsToAccept.filter((element: any) => {
          return element.name !== 'PartnerContract';
        });
      }
    }, err => console.log(err));
    

  }

  onOpenWhatsApp() {
    window.open('https://api.whatsapp.com/send?phone=551130030694', '_blank');
  }

  onNotNow() {
    this.logout();
    this.bsModalRef.content.callback(null);
    this.bsModalRef.hide();
    this.router.navigate(['/login']);
  }

  onAcceptTerms() {
    const userIP = localStorage.getItem('client-ip');;
    const terms: TermsOfUse[] = [];
    this.terms.forEach((element: any) => {
      terms.push({
        termId: element.id,
        ip: userIP,
        dateTime: this.accptedTermsTime.toISOString(),
        dateTimeStr: this.accptedTermsTime.format('YYYY-MM-DDTHH:mm:ss [GMT]Z')
      })
    });

    const request = { terms };

    this.agentService.saveTermsConditions(request).subscribe((result: any) => {
      if (result) {
        this.bsModalRef.content.callback(true);
        this.bsModalRef.hide();
      }
    }, err => {
      this.onNotNow();
    });
  }

  getTermNameToTranslate(name: string) {
    switch (name) {
      case 'TermsAndConditionsPdf':
        return 'modal-terms-comissions.link-term'
        break;
      default:
        break;
    }
  }

  setAcceptedTerms(): void {
    this.checkedTerms = !this.checkedTerms;
    const dateTimezone = dayjs(new Date());
    this.accptedTermsTime = dateTimezone;
  }

  propertyObject<T>(arrConfig: any): T {
    return arrConfig.reduce((obj, item) => (
      {
        ...obj,
        [item.name]: item.value
      }), {})
  }

  private logout() {
    this.authService.logout();
  }

  public decline(): void {
    this.bsModalRef.content.callback(null);
    this.bsModalRef.hide();
  }

  public accept(): void {
    this.bsModalRef.content.callback(true);
    this.bsModalRef.hide();
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(null);
    this.bsModalRef.hide();
  }

}
