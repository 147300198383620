<nav class="main-header navbar navbar-expand navbar-dark navbar-light" style="background-color: #343a40;">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link d-flex align-items-center" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
    </li>
    <!-- <li class="nav-item d-block d-sm-none">
      <a class="nav-link adm-area" role="title">
        {{'authentication.restric-area' | translate }}
      </a>
    </li> -->
  </ul>

  <ul class="navbar-nav ml-auto">

    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown" href="#">
        <div class="mr-md-2 mr-lg-2 mr-xl-2">
          <img [src]="currentFlag?.icon" style="width: 22px;" alt="">
        </div>
        <span class="color text-white d-none d-sm-inline-block">
          {{currentFlag?.displayName}}
        </span>
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <div *ngFor="let language of languages">
          <a (click)="useLanguage(language)" class="dropdown-item">
            <img [src]="language.icon" style="width: 25px;" alt="">
            &nbsp;{{language.displayName}}
            <i class="fa fa-check" *ngIf="idioma === language.codeIso" aria-hidden="true"></i>
          </a>
          <div class="dropdown-divider"></div>
        </div>
      </div>
    </li>

    <li class="nav-item d-none d-sm-block" *ngIf="!isHidden">
      <a [href]="agencySite" class="nav-link">{{'general.dashboard.access-agency' | translate}}</a>
    </li>
    <li class="nav-item d-block d-sm-none" *ngIf="!isHidden">
      <a class="nav-link" role="button" [href]="agencySite">
        <span class="material-icons d-flex align-items-center" style="font-size: 20px;">screen_share</span>
      </a>
    </li>

    <li class="nav-item d-block d-sm-none" routerLink="/login" (click)="sair()">
      <a class="nav-link" role="button">
        <span class="material-icons d-flex align-items-center" style="font-size: 20px;">logout</span>
      </a>
    </li>
    <li class="nav-item d-none d-sm-block logout" routerLink="/login" (click)="sair()">
      <a class="nav-link">{{'general.button.title.logout' | translate}}</a>
    </li>
  </ul>
</nav>
