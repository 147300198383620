import { ComissionAgentModel } from '../../comissions/models/comission-agent.model';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TokenService } from 'src/app/core/auth/services/token.service';
import { BaseService } from 'src/app/core/services/base.service';
import { SiteModel } from '../../site/models/site.model';
import { AgentModel } from '../models/partner.model';
import { ResultSearchModel } from '../../../shared/components/base-resource-form/model/result-search.model';
import { TrackingComissionModel } from '../../../shared/models/tracking-comission.model';
import { PartenerEditEmail } from '../models/partner-edit-email';
import { PartnerCompany } from '../models/partner-company.model';
import { PartnerProcurator } from '../models/partner-procurator.model';
import { PartnerBank } from '../models/partner-bank.model';
import { PartnerAddress } from '../models/partner-address.model';

@Injectable({
  providedIn: 'root',
})
export class AgentService extends BaseService<any, string> {
  agent: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    protected injector: Injector,
    private tokenService: TokenService
  ) {
    super(injector, '/api/agent');
  }

  public pageableSearch(
    queryString: string
  ): Observable<ResultSearchModel<AgentModel>> {
    return this.search(queryString);
  }

  public pageableSearchbyInstitutionQuery(
    queryString: string
  ): Observable<ResultSearchModel<AgentModel>> {
    return this.search(queryString, '/by-instituion');
  }

  public update(agent: AgentModel) {
    return this.post(agent, `/edit/${agent.id}`);
  }

  public updateAgency(agentId: number, agency: SiteModel) {
    return this.putAny(agentId, agency, '/update-agency');
  }

  public getStatus(): Observable<any> {
    return this.get('/status');
  }

  public getAgent(id: string): Observable<any> {
    return this.get(`?id=${id}`);
  }

  public getAgency(id: number): Observable<any> {
    return this.get(`/agency?agentId=${id}`);
  }

  public getAgentByPerson(personId: string): Observable<any> {
    return this.get(`/get-by-person?personId=${personId}`);
  }

  public updateStatus(agent: any): Observable<any> {
    return this.post(agent, `/update-status/${agent.id}`);
  }

  public completeRegister(finalValue: AgentModel): Observable<any> {
    return this.postAny(finalValue, `/complete-register`);
  }

  public updateAgent(id: number, agent: AgentModel): Observable<any> {
    return this.put(id.toString(), agent);
  }

  public getComission(id: number): Observable<any> {
    return this.get(`/comissions?agentId=${id}`);
  }

  public updateComission(comissionAgent: any): Observable<any> {
    return this.post(comissionAgent, '/save-comissions');
  }

  public updateAgentAdmin(id, procurator: PartnerProcurator): Observable<any> {
    return this.put(id, procurator, `/admin`);
  }

  public updateAgentAdminCompany(id, company: PartnerCompany): Observable<any> {
    return this.put(null, company, `/admin/${id}/company`);
  }

  public updateAgentAdminBank(id, bank: PartnerBank): Observable<any> {
    return this.put(null, bank, `/admin/${id}/bank`);
  }

  public updateAgentAdminAddress(id, address: PartnerAddress): Observable<any> {
    return this.put(null, address, `/admin/${id}/address`);
  }

  public createAgentAdmin(agent: any): Observable<any> {
    return this.post(agent, '');
  }

  public getDestinations(): Observable<any> {
    return this.get(`/priority-destinations`);
  }

  public saveDestinations(destinations: any): Observable<any> {
    return this.post(destinations, '/save-priority-destinations');
  }

  public getExtraFields(): Observable<any> {
    return this.get(`/extra-fields-by-institution`);
  }

  public saveTermsConditions(terms: any): Observable<any> {
    return this.put(null, terms, '/agent-terms');
  }

  public getTrackings(queryString: string): Observable<any> {
    return this.get(`/track-comissions${queryString}`);
  }

  public getAgentByInstitution(id: any): Observable<any> {
    return this.get(
      `/by-instituion/search?institutionId=${id}&page=1&totalPerPage=100000`
    );
  }

  public getReport(queryString: string): Observable<any> {
    return this.getAllPageable(queryString, `/admin/extract-report`);
  }

  public sendWelcomeEmail(agentId: number): Observable<any> {
    return this.get(`/admin/send-welcome-email/${agentId}`);
  }

  public getUserByHash(hash): Observable<any> {
    return this.get(`/new-agent-data?newAgentHash=${hash}`);
  }

  public cancelAccountAgent(agentId) {
    return this.post(null, `/cancel/${agentId}`);
  }

  public getEndTerm() {
    return this.get(`/get-end-term`);
  }

  public setEndTerm() {
    return this.get(`/save-end-term`);
  }

  public chameEmailByAdminMaster(request: PartenerEditEmail): Observable<any> {
    return this.patch(null, request, `/${request.agentId}/update-email`);
  }
}
