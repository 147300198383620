import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, HostListener, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AgentService } from 'src/app/components/admin/partners/services/partner.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import Messages from '../../enums/messages.enum';
import { InstitutionService } from '../../services/institution.service';
import { Page } from '../base-resource-form/model/page.model';
import { ResultSearchModel } from '../base-resource-form/model/result-search.model';

@Component({
  selector: 'search-partner',
  templateUrl: 'search-partner.component.html',
  styleUrls: ['./search-partner.component.scss']
})

export class SearchPartnerComponent implements OnInit {

  institutionId: any;
  agentId: any;
  sale: any;
  enableForm: boolean = false;

  outsideClick: boolean;
  form: UntypedFormGroup;
  formSubmitted: boolean = false;
  partners: any = [];
  institutions: any;

  agencyValName: string;
  agencyNumTotalPage: number = 1;
  agencyCount: number = 0;
  loadingFetchAgent = false;

  documentInputMode: any = '';
  documentMask: any = '';
  mobilePhoneMask: any = '';
  mobileDevice = false;
  modalRefDetail: BsModalRef;
  openDetailModal = false;
  partnerDetail: any;

  page: Page<any> = {};
  cols: any[] = [
    { name: 'agencyName', label: 'modal-search-partner.inputs.agency', applySearch: true },
    { name: 'institutionId', label: 'modal-search-partner.inputs.institution', applySearch: false },
    { name: 'candidatureStatusDescription', label: 'modal-search-partner.modal-detail.inputs.status', applySearch: true },
    { name: 'personName', label: 'modal-search-partner.inputs.name', applySearch: true },
    { name: 'document', label: 'modal-search-partner.inputs.document', applySearch: true },
    { name: 'id', label: 'ID', applySearch: true }
  ];
  searchForm: { institutionId: any; agencyName: any; personName: any; document: any, email: any, phoneNumber: any, agentId: any, agencyDocument: string };
  paginationLabel: any = {
    previousLabel: '<',
    nextLabel: '>',
  };

  constructor(
    public bsModalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    protected translate: TranslateService,
    private agentService: AgentService,
    private instituitionService: InstitutionService,
    private breakpointObserver: BreakpointObserver,
    private modalService: BsModalService,
    private notificationService: NotificationService
  ) {
    this.mobileDevice = false;
    this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
      this.mobileDevice = result.matches;
    });
  }

  ngOnInit() {
    if (!this.sale?.id || this.sale?.isManual) {
      this.enableForm = true;
    }

    this.instituitionService.getInstitution().subscribe((result) => {
      if (result.success) {
        this.institutions = result.data;
      }
    }, (err) => this.handleError(err, this));

    this.form = this.fb.group({
      institutionId: [{ value: null, disabled: !this.enableForm }],
      personName: [{ value: null, disabled: !this.enableForm }],
      document: [{ value: null, disabled: !this.enableForm }],
      email: [{ value: null, disabled: !this.enableForm }],
      phoneNumber: [{ value: null, disabled: !this.enableForm }],
      agencyName: [{ value: null, disabled: !this.enableForm }],
      agentId: [{ value: null, disabled: !this.enableForm }],
      agencyDocument: [{ value: null, disabled: !this.enableForm }],
    });

    if (this.institutionId && this.agentId) {
      this.onSearchAgentById(this.agentId);
      this.form.get('institutionId').patchValue(this.institutionId);
    }
  }

  changeInstitution(): void {
    if (this.form.get('institutionId').value) {
      if (this.enableForm) {
        this.form.get('personName').enable();
        this.form.get('document').enable();
        this.form.get('email').enable();
        this.form.get('phoneNumber').enable();
        this.form.get('agencyName').enable();
        this.form.get('agentId').enable();
      }
      this.institutionId = this.form.get('institutionId').value;
    } else {
      this.institutionId = null;
    }
  }

  onSearchAgentById(id: any): void {
    setTimeout(() => {
      this.agentService.getAgent(id).subscribe(
        (result) => {
          this.partners = [result.data];
          if (this.enableForm) {
            this.form.get('personName').enable();
            this.form.get('document').enable();
            this.form.get('email').enable();
            this.form.get('phoneNumber').enable();
            this.form.get('agencyName').enable();
            this.form.get('id').enable();
          }
          this.form.get('personName').setValue(result.data.name);
          this.form.get('document').setValue(result.data.documentNumber);
          this.form.get('email').setValue(result.data.email);
          this.form.get('phoneNumber').setValue(result.data.mobilePhone);
          this.form.get('agencyName').setValue(result.data.agencyName);
          this.form.get('agentId').setValue(result.data.id);

          const institutionId = this.form.get('institutionId').value;
          const agencyName = result.data.agencyName;
          const personName = result.data.name;
          const document = result.data.documentNumber;
          const email = result.data.email;
          const phoneNumber = result.data.mobilePhone;
          const agentId = result.data.agentId;
          const agencyDocument = result.data.documentNumber;

          this.searchForm = { institutionId, agencyName, personName, document, email, phoneNumber, agentId, agencyDocument };
          this.pageChanged(this.page.currentPage);
        },
        (err) => this.handleError(err, this)
      );
    }, 800);
  }

  getConfigurationValue(name: string, configurations?: any) {
    if (configurations) {
      for (const each of configurations) {
        if (each.name === name) {
          return each.value.toUpperCase();
        }
      }
      return undefined;
    }
  }

  search(): void {
    const formValue = this.form.value;
    const mapped = Object.values(formValue).map(value => !!value);
    const hasValues = mapped.some(value => value);

    if (!hasValues) {
      this.notificationService.messageError(this.translate.instant(Messages.REQUIRED_FIELDS));
      return;
    }

    const institutionId = this.form.get('institutionId').value;
    const agencyName = this.form.get('agencyName').value;
    const personName = this.form.get('personName').value;
    const document = this.form.get('document').value;
    const email = this.form.get('email').value;
    const phoneNumber = this.form.get('phoneNumber').value;
    const agentId = this.form.get('agentId').value;
    const agencyDocument = this.form.get('agencyDocument').value;

    this.searchForm = { institutionId, agencyName, personName, document, email, phoneNumber, agentId, agencyDocument };
    this.pageChanged(this.page.currentPage);
  }

  pageChanged(event: any) {
    this.page.currentPage = !!event ? event : 1;
    this.agentService.pageableSearch(this.createQueryString(this.page.currentPage)).subscribe(result => {
      if (result.success) {
        this.page = this.transformaResult(result);
      }
    }, (error: any) => {
      this.notificationService.messageError(error.error);
    });
  }

  private createQueryString(event: any) {
    this.page.currentPage = !!event ? event : 1;
    let queryString = `page=${this.page.currentPage}&totalPerPage=${5}`;
    queryString = this.getValueFromSearchForm(queryString, 'personName');
    queryString = this.getValueFromSearchForm(queryString, 'document');
    queryString = this.getValueFromSearchForm(queryString, 'email');
    queryString = this.getValueFromSearchForm(queryString, 'phoneNumber');
    queryString = this.getValueFromSearchForm(queryString, 'agencyName');
    queryString = this.getValueFromSearchForm(queryString, 'agentId');
    queryString = this.getValueFromSearchForm(queryString, 'institutionId');
    queryString = this.getValueFromSearchForm(queryString, 'agencyDocument');
    return queryString;
  }

  private getValueFromSearchForm(queryString: string, value: string) {
    if (this.searchForm[value]) {
      queryString += `&${value}=${this.searchForm[value]}`;
    }
    return queryString;
  }

  transformaResult(result: ResultSearchModel<any>): Page<any> {
    const page: Page<any> = {
      itemsPerPage: 5,
      currentPage: result.data.page,
      totalItems: result.data.count,
      rows: result.data.records
    };
    return page;
  }

  public dismiss(): void {
    this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
  }

  public decline(): void {
    this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
  }

  public accept(partner: any): void {
    this.formSubmitted = true;
    partner.agentId = partner.id;
    this.bsModalRef.content.callback(true, partner);
    this.bsModalRef.hide();

  }

  openModalDetail(template: TemplateRef<any>, partner: any): void {
    setTimeout(() => {
      this.agentService.getAgent(partner.id).subscribe(
        (result) => {
          this.partnerDetail = result.data;
          this.openDetailModal = false;
          this.modalRefDetail = this.modalService.show(template, { class: 'modal-lg' });
        },
        (err) => this.handleError(err, this)
      );
    }, 800);
  }

  handleError(error: any, innerThis: any = this): void {
    innerThis.notificationService.messageError(this.translate.instant(Messages.ERROR_INTERNAL));
  }

  @HostListener('document:keydown', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    this.onKeydown(event);
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  // @HostListener('window:keydown.esc', ['$event'])
  // onEsc(event: any): void {
  //   if (event.keyCode === 27) {
  //     event.preventDefault();
  //     this.dismiss();
  //   }
  // }

  // @HostListener('window:mousedown', ['$event'])
  // onClickStarted(event: MouseEvent): void {
  //   if ((event.target as HTMLElement).tagName === 'MODAL-CONTAINER') {
  //     this.outsideClick = true;
  //   }
  // }

  // @HostListener('window:mouseup', ['$event'])
  // onClickStop(event: MouseEvent): void {
  //   if (this.outsideClick) {
  //     this.dismiss();
  //   }
  // }
}
