import { Component, HostListener, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ComissionService } from '../../services/comission.service';

@Component({
  selector: 'app-confim-modal',
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent implements OnInit {

  @Input() message: string;
  @Input() title: string;
  @Input() noCancel: boolean;
  @Input() agentComissions: any;

  outsideClick: boolean;
  comissions: any;

  constructor(
    public bsModalRef: BsModalRef,
    private comissionService: ComissionService
  ) { }

  ngOnInit() {
    this.outsideClick = false;

    if (this.agentComissions) {
      this.comissionService.getComission().subscribe((comissions: any) => {
        this.comissions = comissions?.data;
      })
    }
  }

  public decline(): void {
    this.bsModalRef.content.callback(false);
    this.bsModalRef.hide();
  }

  public accept(): void {
    if (!this.outsideClick) {
      this.bsModalRef.content.callback(true);
      this.bsModalRef.hide();
    }
  }

  public dismiss(): void {
    this.decline();
  }

  @HostListener('window:keydown.esc', ['$event'])
  onEsc(event: any): void {
    if (event.keyCode === 27) {
      event.preventDefault();
      this.dismiss();
    }
  }

  @HostListener('window:mousedown', ['$event'])
  onClickStarted(event: MouseEvent): void {
    if ((event.target as HTMLElement).tagName === 'MODAL-CONTAINER') {
      this.outsideClick = true;
    }
  }

  @HostListener('window:mouseup', ['$event'])
  onClickStop(event: MouseEvent): void {
    if (this.outsideClick) {
      this.dismiss();
    }
  }
}
